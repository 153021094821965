import { useEffect } from "react";
import { useGetScraping } from "../../hooks/scraping/useGetScraping";
import {
  Container,
  DescriptionWrapper,
  LinkFaviconWrapper,
  LinkImageWrapper,
} from "./styled";
import { MODE, ScrapingProps } from "./types";

import { FontSize, FontWeight } from "../../utils/Fonts";
import { darkenColor } from "../../utils/Others";
import Text from "../Text/Text";

export default function Scraping(scrapingProps: ScrapingProps) {
  const { url, backgroundColor, fontColor } = scrapingProps;

  const getMetadata = useGetScraping();

  useEffect(() => {
    if (!url) return;

    getMetadata.get({
      url: url,
    });
  }, [url]);

  const handleNavigate = () => {
    if (!url) return;

    window.open(url, "_blank");
  };

  const darkerBackgroundColor = darkenColor(backgroundColor);

  function cleanUrl(url: string) {
    // Expresión regular para encontrar el protocolo HTTP o HTTPS y la barra diagonal final
    const httpRegex = /^(https?:\/\/)/i;
    const trailingSlashRegex = /\/$/;

    // Eliminar el protocolo HTTP o HTTPS
    let cleanedUrl = url.replace(httpRegex, "");

    // Eliminar la barra diagonal final si está presente
    cleanedUrl = cleanedUrl.replace(trailingSlashRegex, "");

    return cleanedUrl;
  }
  const image = getMetadata.data?.data?.image;

  const favicon = getMetadata.data?.data?.favicon;

  const mode =
    image && image !== ""
      ? MODE.VERTICAL
      : favicon && favicon !== ""
      ? MODE.HORIZONTAL
      : MODE.VERTICAL;

  return (
    <Container
      onClick={handleNavigate}
      mode={mode}
      backgroundColor={darkerBackgroundColor}
    >
      {image && image !== "" && <LinkImageWrapper src={image} />}

      {!image && favicon && favicon !== "" && (
        <LinkFaviconWrapper src={favicon} />
      )}
      <DescriptionWrapper mode={mode}>
        {(getMetadata.data?.data?.title || !!url) && (
          <Text
            value={getMetadata.data?.data?.title || cleanUrl(url!)}
            fontWeight={FontWeight.FONT_BOLD}
            fontSize={FontSize.TEXT_SM}
            fontColor={fontColor}
          />
        )}
        {(getMetadata.data?.data?.description || !!url) && (
          <Text
            value={getMetadata.data?.data?.description ?? url!}
            fontSize={FontSize.TEXT_XXS}
            fontColor={fontColor}
          />
        )}
        {(getMetadata.data?.data?.url || !!url) && (
          <Text
            value={getMetadata.data?.data?.url ?? cleanUrl(url!)}
            fontSize={FontSize.TEXT_XXS}
            fontColor={fontColor}
          />
        )}
      </DescriptionWrapper>
    </Container>
  );
}
