import { useState } from "react";
import { UseGetConnectionProps } from "./types";
import ConnectionService from "../../../domain/service/connection/connection";
import { Connection } from "../../../domain/models/Connection";

const useGetConnection = (
) => {

  const [data, setData] = useState<null | Connection>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const configurationService = new ConnectionService();
  const get = async (props: UseGetConnectionProps ) => {

    const { connectionId } = props;

    try {
      const response = await configurationService.getConnection({
        connectionId,
      });

      setData(response.connection);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, get };
};

export default useGetConnection;
