import { Icons } from "./types";

export const ICONS: Icons = {
    PDF: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.458 6.15598C16.7746 6.15532 16.1194 5.88355 15.6362 5.40034C15.153 4.91713 14.8813 4.26194 14.8806 3.57858V0H3.3636C2.47152 0 1.61598 0.354389 0.985179 0.985187C0.354381 1.61599 0 2.47151 0 3.36359V23.6402C0.00132474 24.5314 0.356287 25.3857 0.986942 26.0154C1.6176 26.6451 2.47238 26.9988 3.3636 26.9988H17.1893C18.0805 26.9988 18.9353 26.6451 19.5659 26.0154C20.1966 25.3857 20.5515 24.5314 20.5529 23.6402V6.15598H17.458Z" fill="#D1E1F6"/>
  <path d="M20.5531 6.15598H17.4582C16.7749 6.15532 16.1197 5.88355 15.6365 5.40034C15.1533 4.91713 14.8815 4.26194 14.8809 3.57858V0L20.5531 6.15598Z" fill="#233348"/>
  <path d="M16.3243 18.8542H3.4199C3.29559 18.8542 3.17636 18.8048 3.08846 18.7169C3.00055 18.629 2.95117 18.5098 2.95117 18.3854C2.9515 18.2613 3.00103 18.1424 3.0889 18.0548C3.17677 17.9672 3.2958 17.918 3.4199 17.918H16.3243C16.4483 17.918 16.5672 17.9672 16.6549 18.0549C16.7426 18.1425 16.7918 18.2615 16.7918 18.3854C16.792 18.4469 16.78 18.5079 16.7566 18.5647C16.7332 18.6216 16.6988 18.6733 16.6553 18.7168C16.6119 18.7603 16.5603 18.7949 16.5035 18.8185C16.4467 18.842 16.3858 18.8542 16.3243 18.8542Z" fill="#757575"/>
  <path d="M16.3243 13.5739H3.4199C3.2958 13.5739 3.17677 13.5247 3.0889 13.437C3.00103 13.3494 2.9515 13.2305 2.95117 13.1064C2.95117 12.9821 3.00055 12.8629 3.08846 12.775C3.17636 12.6871 3.29559 12.6377 3.4199 12.6377H16.3243C16.3858 12.6377 16.4467 12.6498 16.5035 12.6734C16.5603 12.697 16.6119 12.7315 16.6553 12.775C16.6988 12.8186 16.7332 12.8703 16.7566 12.9271C16.78 12.984 16.792 13.0449 16.7918 13.1064C16.7918 13.2304 16.7426 13.3493 16.6549 13.437C16.5672 13.5246 16.4483 13.5739 16.3243 13.5739Z" fill="#757575"/>
  <path d="M16.3243 16.2145H3.4199C3.29559 16.2145 3.17636 16.1651 3.08846 16.0772C3.00055 15.9893 2.95117 15.8701 2.95117 15.7458C2.9515 15.6217 3.00103 15.5028 3.0889 15.4152C3.17677 15.3275 3.2958 15.2783 3.4199 15.2783H16.3243C16.4483 15.2783 16.5672 15.3276 16.6549 15.4152C16.7426 15.5029 16.7918 15.6218 16.7918 15.7458C16.792 15.8073 16.78 15.8682 16.7566 15.9251C16.7332 15.9819 16.6988 16.0336 16.6553 16.0772C16.6119 16.1207 16.5603 16.1553 16.5035 16.1788C16.4467 16.2024 16.3858 16.2145 16.3243 16.2145Z" fill="#757575"/>
  <path d="M11.2346 10.9343H3.4199C3.2958 10.9343 3.17677 10.8851 3.0889 10.7974C3.00103 10.7098 2.9515 10.5909 2.95117 10.4668C2.95117 10.3425 3.00055 10.2232 3.08846 10.1353C3.17636 10.0474 3.29559 9.99805 3.4199 9.99805H11.2346C11.3587 9.99838 11.4776 10.0479 11.5652 10.1358C11.6528 10.2236 11.702 10.3427 11.702 10.4668C11.7017 10.5907 11.6524 10.7094 11.5648 10.797C11.4772 10.8846 11.3584 10.9339 11.2346 10.9343Z" fill="#757575"/>
  <path d="M11.2346 8.29461H3.4199C3.2958 8.29461 3.17677 8.24538 3.0889 8.15774C3.00103 8.07011 2.9515 7.95123 2.95117 7.82713C2.95117 7.70281 3.00055 7.58356 3.08846 7.49566C3.17636 7.40776 3.29559 7.3584 3.4199 7.3584H11.2346C11.3587 7.35873 11.4776 7.40825 11.5652 7.49612C11.6528 7.58399 11.702 7.70303 11.702 7.82713C11.7017 7.95101 11.6524 8.06972 11.5648 8.15731C11.4772 8.24491 11.3584 8.29428 11.2346 8.29461Z" fill="#757575"/>
  <path d="M22.9912 21.3252H7.36191C6.25324 21.3252 5.35449 22.2239 5.35449 23.3326V27.9924C5.35449 29.1011 6.25324 29.9998 7.36191 29.9998H22.9912C24.0999 29.9998 24.9986 29.1011 24.9986 27.9924V23.3326C24.9986 22.2239 24.0999 21.3252 22.9912 21.3252Z" fill="#233348"/>
  <path d="M10.482 26.6719V27.5806H9.54199V26.6719H10.482Z" fill="white"/>
  <path d="M11.8648 26.2612V27.5799H10.9561V23.8213H12.4285C12.8747 23.8213 13.216 23.9325 13.4497 24.1538C13.568 24.27 13.6603 24.4099 13.7206 24.5644C13.781 24.7189 13.8079 24.8843 13.7997 25.05C13.8042 25.2685 13.7503 25.4843 13.6434 25.6749C13.5343 25.8614 13.3726 26.0114 13.1785 26.1062C12.9443 26.2168 12.6873 26.2704 12.4285 26.2624L11.8648 26.2612ZM12.8747 25.05C12.8747 24.7166 12.6922 24.55 12.3272 24.55H11.8648V25.5287H12.3272C12.6922 25.532 12.8747 25.3725 12.8747 25.05Z" fill="white"/>
  <path d="M17.3907 26.6743C17.2342 26.9585 16.9978 27.1906 16.7107 27.3418C16.3877 27.5072 16.0285 27.5892 15.6658 27.5806H14.2471V23.822H15.6658C16.0288 23.8129 16.3885 23.8931 16.7132 24.0557C16.9995 24.2037 17.2353 24.4334 17.3907 24.7157C17.5533 25.0154 17.6352 25.3522 17.6282 25.6932C17.635 26.0352 17.5531 26.3732 17.3907 26.6743ZM16.4132 26.4856C16.5148 26.3813 16.5936 26.257 16.6444 26.1205C16.6951 25.984 16.7169 25.8385 16.7082 25.6932C16.717 25.548 16.6952 25.4026 16.6444 25.2663C16.5936 25.1301 16.5149 25.0059 16.4132 24.9019C16.1859 24.7024 15.8889 24.6009 15.587 24.6194H15.1558V26.7668H15.587C15.8887 26.7852 16.1854 26.6842 16.4132 26.4856Z" fill="white"/>
  <path d="M20.5937 23.8213V24.545H19.0525V25.3687H20.2425V26.0599H19.0525V27.5799H18.1426V23.8213H20.5937Z" fill="white"/>
  <path d="M16.0949 7.3584H13.885C13.4998 7.3584 13.1875 7.67067 13.1875 8.05587V10.2658C13.1875 10.651 13.4998 10.9632 13.885 10.9632H16.0949C16.4801 10.9632 16.7923 10.651 16.7923 10.2658V8.05587C16.7923 7.67067 16.4801 7.3584 16.0949 7.3584Z" fill="#757575"/>
  </svg>
  `,
    PNG: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4587 6.155C16.7754 6.15434 16.1201 5.88257 15.6369 5.39934C15.1537 4.91611 14.8819 4.26089 14.8813 3.5775V0H3.36375C2.47162 0 1.61605 0.354396 0.985222 0.985222C0.354396 1.61605 0 2.47162 0 3.36375V23.6412C0.0013248 24.5325 0.356302 25.3868 0.986984 26.0165C1.61767 26.6463 2.47249 27 3.36375 27H17.19C18.0815 27 18.9365 26.6461 19.5672 26.0161C20.1979 25.3861 20.5528 24.5315 20.5538 23.64V6.155H17.4587Z" fill="#D1E1F6"/>
    <path d="M20.5534 6.155H17.4584C16.775 6.15434 16.1198 5.88257 15.6365 5.39934C15.1533 4.91611 14.8815 4.26089 14.8809 3.5775V0L20.5534 6.155Z" fill="#757575"/>
    <path d="M22.993 21.3252H7.36297C6.25426 21.3252 5.35547 22.224 5.35547 23.3327V27.9927C5.35547 29.1014 6.25426 30.0002 7.36297 30.0002H22.993C24.1017 30.0002 25.0005 29.1014 25.0005 27.9927V23.3327C25.0005 22.224 24.1017 21.3252 22.993 21.3252Z" fill="#233348"/>
    <path d="M9.88266 26.6719V27.5806H8.94141V26.6719H9.88266Z" fill="white"/>
    <path d="M11.2655 26.2625V27.58H10.3555V23.8213H11.828C12.2755 23.8213 12.6159 23.9321 12.8492 24.1538C12.9677 24.27 13.0602 24.4099 13.1207 24.5643C13.1813 24.7188 13.2084 24.8843 13.2005 25.05C13.2044 25.2687 13.15 25.4844 13.043 25.675C12.9344 25.8616 12.7725 26.0114 12.578 26.105C12.3441 26.2166 12.087 26.2706 11.828 26.2625H11.2655ZM12.2755 25.05C12.2755 24.7167 12.093 24.55 11.728 24.55H11.2655V25.5288H11.728C12.093 25.5321 12.2755 25.3725 12.2755 25.05Z" fill="white"/>
    <path d="M16.9855 27.58H16.0768L14.5543 25.2725V27.58H13.6455V23.8213H14.5543L16.0755 26.15V23.8213H16.9843L16.9855 27.58Z" fill="white"/>
    <path d="M20.5359 24.1252C20.8392 24.3522 21.043 24.6876 21.1046 25.0614H20.1421C20.079 24.9275 19.9777 24.8153 19.8509 24.7389C19.7077 24.654 19.5436 24.6107 19.3771 24.6139C19.2478 24.6086 19.1189 24.6317 18.9994 24.6816C18.88 24.7314 18.773 24.8069 18.6859 24.9027C18.5032 25.1222 18.4104 25.4026 18.4259 25.6877C18.4259 26.0452 18.5171 26.3198 18.6996 26.5114C18.8022 26.611 18.9245 26.688 19.0586 26.7375C19.1927 26.7871 19.3357 26.808 19.4784 26.7989C19.6823 26.8025 19.8819 26.7397 20.0471 26.6202C20.2154 26.4942 20.3369 26.3156 20.3921 26.1127H19.2446V25.4589H21.1534V26.3752C21.0794 26.5987 20.9636 26.8062 20.8121 26.9864C20.6485 27.1805 20.4446 27.3366 20.2146 27.4439C19.9539 27.5643 19.6693 27.6241 19.3821 27.6189C19.0332 27.6278 18.6882 27.5441 18.3821 27.3764C18.102 27.2194 17.8739 26.9839 17.7259 26.6989C17.5674 26.392 17.488 26.0505 17.4946 25.7052C17.4871 25.3578 17.5666 25.014 17.7259 24.7052C17.8737 24.4209 18.1008 24.1856 18.3796 24.0277C18.6813 23.8615 19.0216 23.7783 19.3659 23.7864C19.7827 23.766 20.1944 23.8852 20.5359 24.1252Z" fill="white"/>
    <path d="M6.83789 8.33984H5.11914V10.0586H6.83789V8.33984Z" fill="#757575"/>
    <path d="M8.55762 10.0586H6.83887V11.7773H8.55762V10.0586Z" fill="#757575"/>
    <path d="M10.2764 8.33984H8.55762V10.0586H10.2764V8.33984Z" fill="#757575"/>
    <path d="M11.9951 10.0586H10.2764V11.7773H11.9951V10.0586Z" fill="#757575"/>
    <path d="M13.7158 8.33984H11.9971V10.0586H13.7158V8.33984Z" fill="#757575"/>
    <path d="M15.4346 10.0586H13.7158V11.7773H15.4346V10.0586Z" fill="#757575"/>
    <path d="M6.83789 11.7744H5.11914V13.4932H6.83789V11.7744Z" fill="#757575"/>
    <path d="M8.55762 13.4932H6.83887V15.2119H8.55762V13.4932Z" fill="#757575"/>
    <path d="M10.2764 11.7744H8.55762V13.4932H10.2764V11.7744Z" fill="#757575"/>
    <path d="M11.9951 13.4932H10.2764V15.2119H11.9951V13.4932Z" fill="#757575"/>
    <path d="M13.7158 11.7744H11.9971V13.4932H13.7158V11.7744Z" fill="#757575"/>
    <path d="M15.4346 13.4932H13.7158V15.2119H15.4346V13.4932Z" fill="#757575"/>
    <path d="M6.83789 15.2266H5.11914V16.9453H6.83789V15.2266Z" fill="#757575"/>
    <path d="M8.55762 16.9453H6.83887V18.6641H8.55762V16.9453Z" fill="#757575"/>
    <path d="M10.2764 15.2266H8.55762V16.9453H10.2764V15.2266Z" fill="#757575"/>
    <path d="M11.9951 16.9453H10.2764V18.6641H11.9951V16.9453Z" fill="#757575"/>
    <path d="M13.7158 15.2266H11.9971V16.9453H13.7158V15.2266Z" fill="#757575"/>
    <path d="M15.4346 16.9453H13.7158V18.6641H15.4346V16.9453Z" fill="#757575"/>
    </svg>  
  `,
    JPG: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4588 6.155C16.7753 6.15467 16.1199 5.88301 15.6366 5.3997C15.1533 4.9164 14.8816 4.26099 14.8813 3.5775V0H3.36377C2.92193 -3.05075e-08 2.48442 0.0870421 2.07623 0.256165C1.66804 0.425287 1.29714 0.673171 0.984772 0.985657C0.672403 1.29814 0.424678 1.66911 0.255707 2.07736C0.0867363 2.48562 -0.000163959 2.92317 2.32236e-07 3.36501V23.6413C-0.000163959 24.0831 0.0867363 24.5206 0.255707 24.9289C0.424678 25.3371 0.672403 25.7081 0.984772 26.0206C1.29714 26.3331 1.66804 26.581 2.07623 26.7501C2.48442 26.9192 2.92193 27.0063 3.36377 27.0063H17.1888C17.6306 27.0063 18.0681 26.9192 18.4763 26.7501C18.8845 26.581 19.2553 26.3331 19.5677 26.0206C19.8801 25.7081 20.1278 25.3371 20.2968 24.9289C20.4658 24.5206 20.5527 24.0831 20.5525 23.6413V6.15625L17.4588 6.155Z" fill="#D1E1F6"/>
    <path d="M20.5521 6.155H17.4584C16.7749 6.15467 16.1195 5.88301 15.6362 5.3997C15.1529 4.9164 14.8812 4.26099 14.8809 3.5775V0L20.5521 6.155Z" fill="#757575"/>
    <path d="M22.993 21.3252H7.36298C6.25426 21.3252 5.35547 22.224 5.35547 23.3327V27.9927C5.35547 29.1014 6.25426 30.0002 7.36298 30.0002H22.993C24.1017 30.0002 25.0005 29.1014 25.0005 27.9927V23.3327C25.0005 22.224 24.1017 21.3252 22.993 21.3252Z" fill="#233348"/>
    <path d="M10.5272 26.6719V27.5819H9.58594V26.6719H10.5272Z" fill="white"/>
    <path d="M13.321 23.8223V26.3898C13.321 26.7839 13.214 27.0869 12.9998 27.2985C12.8802 27.4088 12.7397 27.4938 12.5866 27.5486C12.4335 27.6033 12.2709 27.6267 12.1086 27.6173C11.9307 27.6268 11.7528 27.601 11.585 27.5414C11.4172 27.4817 11.2629 27.3894 11.131 27.2698C10.8902 27.0373 10.7694 26.7039 10.7686 26.2698H11.6673C11.6673 26.621 11.801 26.796 12.066 26.796C12.2973 26.796 12.4123 26.661 12.4123 26.3923V23.8248L13.321 23.8223Z" fill="white"/>
    <path d="M14.8287 26.2623V27.581H13.9199V23.8223H15.3924C15.8383 23.8223 16.1782 23.9327 16.4124 24.1535C16.5309 24.2696 16.6235 24.4095 16.684 24.564C16.7446 24.7185 16.7717 24.8841 16.7637 25.0498C16.7671 25.2682 16.7132 25.4837 16.6074 25.6748C16.4986 25.8611 16.3367 26.0108 16.1424 26.1048C15.9084 26.2159 15.6514 26.2698 15.3924 26.2623H14.8287ZM15.8387 25.0498C15.8387 24.7164 15.6562 24.5498 15.2912 24.5498H14.8287V25.5285H15.2912C15.6562 25.5319 15.8387 25.3723 15.8387 25.0498Z" fill="white"/>
    <path d="M20.135 24.1254C20.4377 24.353 20.6413 24.6882 20.7038 25.0616H19.7413C19.6762 24.9308 19.5734 24.8224 19.4463 24.7504C19.3031 24.6653 19.139 24.622 18.9725 24.6254C18.8433 24.6201 18.7145 24.6434 18.5952 24.6935C18.476 24.7436 18.3692 24.8194 18.2825 24.9154C18.0992 25.134 18.0058 25.4142 18.0213 25.6991C18.0213 26.0566 18.1125 26.3312 18.295 26.5229C18.3977 26.6224 18.52 26.6994 18.6541 26.7489C18.7882 26.7984 18.9311 26.8193 19.0738 26.8104C19.2778 26.8141 19.4774 26.7514 19.6425 26.6316C19.8112 26.5057 19.933 26.3271 19.9888 26.1241H18.84V25.4704H20.7488V26.3691C20.6759 26.5929 20.5604 26.8004 20.4088 26.9804C20.2446 27.1743 20.0403 27.3304 19.81 27.4379C19.5497 27.5582 19.2655 27.618 18.9788 27.6129C18.63 27.622 18.285 27.5388 17.9788 27.3716C17.6986 27.2141 17.4706 26.9782 17.3225 26.6929C17.1635 26.384 17.084 26.0403 17.0913 25.6929C17.085 25.3476 17.1645 25.0062 17.3225 24.6991C17.4703 24.4145 17.6974 24.1787 17.9763 24.0204C18.2781 23.8545 18.6183 23.7713 18.9625 23.7791C19.381 23.7609 19.7936 23.8827 20.135 24.1254Z" fill="white"/>
    <path d="M13.0375 18.3019H7.5163C6.97579 18.3012 6.45761 18.0862 6.07541 17.704C5.69321 17.3218 5.4782 16.8036 5.47754 16.2631V10.7419C5.4782 10.2014 5.69321 9.68319 6.07541 9.30099C6.45761 8.9188 6.97579 8.70379 7.5163 8.70312H13.0375C13.5779 8.70379 14.096 8.91884 14.478 9.30107C14.86 9.6833 15.0747 10.2015 15.075 10.7419V16.2631C15.0747 16.8035 14.86 17.3217 14.478 17.7039C14.096 18.0862 13.5779 18.3012 13.0375 18.3019ZM7.5163 9.60437C7.21471 9.6047 6.92556 9.72466 6.71231 9.93791C6.49906 10.1512 6.37912 10.4403 6.37878 10.7419V16.2631C6.37912 16.5647 6.49906 16.8538 6.71231 17.0671C6.92556 17.2803 7.21471 17.4003 7.5163 17.4006H13.0375C13.339 17.4003 13.628 17.2803 13.8411 17.067C14.0541 16.8537 14.1738 16.5646 14.1738 16.2631V10.7419C14.1738 10.4404 14.0541 10.1513 13.8411 9.93799C13.628 9.7247 13.339 9.6047 13.0375 9.60437H7.5163Z" fill="#757575"/>
    <path d="M14.6249 15.2125V16.2625C14.6246 16.6834 14.4572 17.087 14.1596 17.3847C13.862 17.6823 13.4584 17.8497 13.0374 17.85H7.5162C7.09517 17.85 6.69139 17.6827 6.39368 17.385C6.09596 17.0873 5.92871 16.6835 5.92871 16.2625V14.5C6.81246 14.31 8.1587 14.25 9.79245 14.9025L10.805 13.9375L11.4949 15.6875C11.4949 15.6875 11.6799 15.0437 12.2774 15.135C12.8749 15.2262 13.8424 15.55 14.2562 15.2737C14.3677 15.2094 14.4987 15.1877 14.6249 15.2125Z" fill="#757575"/>
    <path d="M12.6909 11.8691C13.0299 11.8691 13.3047 11.5943 13.3047 11.2554C13.3047 10.9164 13.0299 10.6416 12.6909 10.6416C12.3519 10.6416 12.0771 10.9164 12.0771 11.2554C12.0771 11.5943 12.3519 11.8691 12.6909 11.8691Z" fill="#757575"/>
    </svg>
    
  `,
    MP3: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4588 6.15501C16.7754 6.15435 16.1202 5.88258 15.6369 5.39935C15.1537 4.91612 14.8819 4.2609 14.8813 3.57751V0H3.36375C2.47163 0 1.61605 0.354393 0.985224 0.98522C0.354397 1.61605 0 2.47163 0 3.36376V23.6413C0.0013248 24.5326 0.356303 25.3869 0.986986 26.0166C1.61767 26.6463 2.4725 27.0001 3.36375 27.0001H17.19C18.0815 27.0001 18.9365 26.6462 19.5672 26.0162C20.198 25.3861 20.5528 24.5315 20.5538 23.64V6.15501H17.4588Z" fill="#D1E1F6"/>
    <path d="M20.5534 6.15501H17.4584C16.775 6.15435 16.1198 5.88258 15.6365 5.39935C15.1533 4.91612 14.8815 4.2609 14.8809 3.57751V0L20.5534 6.15501Z" fill="#757575"/>
    <path d="M22.993 21.3252H7.36297C6.25426 21.3252 5.35547 22.224 5.35547 23.3327V27.9927C5.35547 29.1014 6.25426 30.0002 7.36297 30.0002H22.993C24.1017 30.0002 25.0005 29.1014 25.0005 27.9927V23.3327C25.0005 22.224 24.1017 21.3252 22.993 21.3252Z" fill="#233348"/>
    <path d="M9.83285 26.6719V27.5806H8.8916V26.6719H9.83285Z" fill="white"/>
    <path d="M14.5325 23.8477V27.5802H13.6237V25.2502L12.815 27.5839H12.0287L11.215 25.2452V27.5839H10.3037V23.8514H11.415L12.43 26.4514L13.43 23.8514L14.5325 23.8477Z" fill="white"/>
    <path d="M16.0689 26.2613V27.58H15.1602V23.8213H16.6327C17.0789 23.8213 17.4202 23.9325 17.6539 24.1538C17.7721 24.2698 17.8644 24.4096 17.9247 24.5638C17.985 24.7181 18.012 24.8834 18.0039 25.0488C18.0084 25.2673 17.9545 25.4831 17.8477 25.6738C17.7385 25.8602 17.5768 26.0102 17.3827 26.105C17.1483 26.2151 16.8914 26.2686 16.6327 26.2613H16.0689ZM17.0789 25.0488C17.0789 24.7205 16.8964 24.5559 16.5314 24.555H16.0689V25.5338H16.5314C16.8964 25.5338 17.0789 25.3721 17.0789 25.0488Z" fill="white"/>
    <path d="M20.418 23.7921C20.5946 23.8723 20.7437 24.0028 20.8467 24.1672C20.9449 24.3281 20.9956 24.5136 20.993 24.7022C21.0029 24.9151 20.9359 25.1245 20.8042 25.2922C20.6842 25.4374 20.5178 25.5368 20.333 25.5734V25.6009C20.8196 25.7559 21.0626 26.0588 21.0617 26.5097C21.0653 26.7117 21.0139 26.9108 20.913 27.0859C20.8106 27.2572 20.6596 27.3943 20.4792 27.4797C20.2656 27.5787 20.0321 27.627 19.7967 27.6209C19.3642 27.6209 19.0205 27.5147 18.768 27.3022C18.5155 27.0897 18.3767 26.7647 18.3555 26.3284H19.243C19.2419 26.4718 19.291 26.6111 19.3817 26.7222C19.4297 26.7723 19.4883 26.8112 19.5531 26.8361C19.6179 26.861 19.6875 26.8711 19.7567 26.8659C19.8137 26.869 19.8707 26.8601 19.9241 26.8399C19.9775 26.8197 20.0261 26.7885 20.0667 26.7484C20.1052 26.7083 20.1353 26.6608 20.155 26.6089C20.1748 26.5569 20.1839 26.5015 20.1817 26.4459C20.1817 26.1367 19.9584 25.9826 19.5117 25.9834H19.3417V25.2447H19.5067C19.9067 25.2505 20.1067 25.1067 20.1067 24.8134C20.1104 24.7598 20.1031 24.7059 20.0853 24.6552C20.0674 24.6045 20.0394 24.5579 20.003 24.5184C19.966 24.483 19.9223 24.4556 19.8743 24.4377C19.8264 24.4199 19.7753 24.412 19.7242 24.4147C19.6676 24.4113 19.611 24.4208 19.5586 24.4424C19.5062 24.4641 19.4594 24.4973 19.4217 24.5397C19.3431 24.6392 19.2992 24.7616 19.2967 24.8884H18.408C18.4058 24.7185 18.4379 24.5499 18.5024 24.3927C18.5668 24.2355 18.6622 24.0929 18.783 23.9734C19.013 23.7609 19.3355 23.6546 19.7505 23.6546C19.9805 23.6497 20.2086 23.6967 20.418 23.7921Z" fill="white"/>
    <path d="M15.029 16.4902H13.6602V10.9577C13.6602 10.7724 13.5866 10.5946 13.4555 10.4636C13.3245 10.3326 13.1468 10.2589 12.9615 10.2589H9.46145C9.36969 10.2589 9.27883 10.277 9.19405 10.3121C9.10927 10.3473 9.03224 10.3987 8.96736 10.4636C8.90247 10.5285 8.85101 10.6055 8.81589 10.6903C8.78077 10.7751 8.7627 10.8659 8.7627 10.9577V16.4902H7.3877V10.4902C7.3877 10.2797 7.42919 10.0712 7.5098 9.87666C7.59042 9.68215 7.70856 9.50544 7.8575 9.35661C8.00644 9.20779 8.18325 9.08978 8.37782 9.00932C8.57239 8.92886 8.7809 8.88753 8.99145 8.8877H13.429C13.854 8.8877 14.2616 9.05653 14.5621 9.35706C14.8626 9.65758 15.0315 10.0652 15.0315 10.4902L15.029 16.4902Z" fill="#757575"/>
    <path d="M13.4067 18.1122C14.3028 18.1122 15.0292 17.3858 15.0292 16.4897C15.0292 15.5936 14.3028 14.8672 13.4067 14.8672C12.5106 14.8672 11.7842 15.5936 11.7842 16.4897C11.7842 17.3858 12.5106 18.1122 13.4067 18.1122Z" fill="#757575"/>
    <path d="M7.14692 18.1122C8.043 18.1122 8.76942 17.3858 8.76942 16.4897C8.76942 15.5936 8.043 14.8672 7.14692 14.8672C6.25083 14.8672 5.52441 15.5936 5.52441 16.4897C5.52441 17.3858 6.25083 18.1122 7.14692 18.1122Z" fill="#757575"/>
    </svg>    
  `,
    GIF: `<svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4588 6.15501C16.7754 6.15435 16.1202 5.88258 15.6369 5.39935C15.1537 4.91611 14.8819 4.2609 14.8813 3.5775V2.32277e-07H3.36501C2.92317 -0.000163959 2.48562 0.0867211 2.07736 0.255692C1.66911 0.424663 1.29814 0.672411 0.985658 0.984781C0.673172 1.29715 0.425288 1.66802 0.256165 2.07621C0.0870422 2.4844 -3.05076e-08 2.92191 0 3.36375V23.6413C0.00132506 24.5328 0.356475 25.3872 0.987428 26.017C1.61838 26.6468 2.47354 27.0004 3.36501 27H17.19C18.0821 27 18.9377 26.6456 19.5686 26.0148C20.1994 25.384 20.5538 24.5284 20.5538 23.6363V6.15501H17.4588Z" fill="#D1E1F6"/>
    <path d="M22.994 21.3252H7.36395C6.25523 21.3252 5.35645 22.224 5.35645 23.3327V27.9927C5.35645 29.1014 6.25523 30.0002 7.36395 30.0002H22.994C24.1027 30.0002 25.0015 29.1014 25.0015 27.9927V23.3327C25.0015 22.224 24.1027 21.3252 22.994 21.3252Z" fill="#233348"/>
    <path d="M11.3075 26.6719V27.5806H10.3662V26.6719H11.3075Z" fill="white"/>
    <path d="M14.705 24.1252C15.0084 24.3522 15.2122 24.6876 15.2738 25.0614H14.3113C14.2482 24.9271 14.1463 24.8148 14.0188 24.7389C13.876 24.654 13.7124 24.6107 13.5463 24.6139C13.417 24.6086 13.288 24.6317 13.1686 24.6816C13.0492 24.7314 12.9421 24.8069 12.855 24.9027C12.672 25.122 12.5786 25.4024 12.5938 25.6877C12.5938 26.0452 12.685 26.3198 12.8675 26.5114C12.9703 26.6111 13.0928 26.6881 13.2272 26.7376C13.3615 26.7871 13.5047 26.808 13.6475 26.7989C13.8515 26.8025 14.051 26.7397 14.2163 26.6202C14.3842 26.4938 14.5055 26.3153 14.5613 26.1127H13.4125V25.4589H15.3213V26.3752C15.2483 26.5989 15.1329 26.8065 14.9813 26.9864C14.8173 27.1801 14.6135 27.3361 14.3838 27.4439C14.123 27.5642 13.8384 27.624 13.5513 27.6189C13.2024 27.6279 12.8573 27.5443 12.5513 27.3764C12.2711 27.2191 12.0427 26.9837 11.8938 26.6989C11.7359 26.3918 11.6564 26.0504 11.6625 25.7052C11.6556 25.3578 11.7351 25.0142 11.8938 24.7052C12.0424 24.4211 12.2699 24.1858 12.5488 24.0277C12.8504 23.8615 13.1907 23.7783 13.535 23.7864C13.9519 23.766 14.3636 23.8852 14.705 24.1252Z" fill="white"/>
    <path d="M16.7417 23.8213V27.58H15.833V23.8213H16.7417Z" fill="white"/>
    <path d="M19.8204 23.8213V24.545H18.2779V25.375H19.4692V26.0663H18.2779V27.5863H17.3691V23.8275L19.8204 23.8213Z" fill="white"/>
    <path d="M20.5543 6.15501H17.4594C16.776 6.15435 16.1207 5.88258 15.6375 5.39935C15.1543 4.91611 14.8825 4.2609 14.8818 3.5775V0L20.5543 6.15501Z" fill="#757575"/>
    <path d="M9.70528 19.3041C8.34158 19.3025 7.0342 18.76 6.06992 17.7957C5.10563 16.8314 4.56318 15.5241 4.56152 14.1604C4.56318 12.7967 5.10563 11.4893 6.06992 10.525C7.0342 9.56071 8.34158 9.01826 9.70528 9.0166C11.069 9.01826 12.3764 9.56071 13.3406 10.525C14.3049 11.4893 14.8474 12.7967 14.849 14.1604C14.8474 15.5241 14.3049 16.8314 13.3406 17.7957C12.3764 18.76 11.069 19.3025 9.70528 19.3041ZM9.70528 9.91786C8.58071 9.91984 7.50276 10.3675 6.70757 11.1626C5.91237 11.9578 5.46475 13.0358 5.46277 14.1604C5.46475 15.2849 5.91237 16.3629 6.70757 17.1581C7.50276 17.9533 8.58071 18.4009 9.70528 18.4029C10.8299 18.4009 11.9078 17.9533 12.703 17.1581C13.4982 16.3629 13.9458 15.2849 13.9478 14.1604C13.9458 13.0358 13.4982 11.9578 12.703 11.1626C11.9078 10.3675 10.8299 9.91984 9.70528 9.91786Z" fill="#757575"/>
    <path d="M9.70493 17.0426C9.11715 17.0434 8.53827 16.8991 8.0197 16.6223C7.50114 16.3456 7.05894 15.9451 6.73242 15.4563L7.48242 14.9563C7.72633 15.3214 8.05654 15.6207 8.44379 15.8276C8.83104 16.0345 9.26336 16.1427 9.70243 16.1426C10.1416 16.1428 10.5741 16.0345 10.9614 15.8274C11.3487 15.6202 11.6788 15.3206 11.9224 14.9551L12.6724 15.4551C12.3466 15.9434 11.9053 16.3438 11.3876 16.6207C10.87 16.8977 10.292 17.0426 9.70493 17.0426Z" fill="#757575"/>
    <path d="M8.27844 13.3713C8.60912 13.3713 8.87718 13.1033 8.87718 12.7726C8.87718 12.4419 8.60912 12.1738 8.27844 12.1738C7.94775 12.1738 7.67969 12.4419 7.67969 12.7726C7.67969 13.1033 7.94775 13.3713 8.27844 13.3713Z" fill="#757575"/>
    <path d="M11.131 13.3713C11.4617 13.3713 11.7297 13.1033 11.7297 12.7726C11.7297 12.4419 11.4617 12.1738 11.131 12.1738C10.8003 12.1738 10.5322 12.4419 10.5322 12.7726C10.5322 13.1033 10.8003 13.3713 11.131 13.3713Z" fill="#757575"/>
    </svg>
    
  `,
    MP4: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4587 6.155C16.7753 6.15434 16.1201 5.88257 15.6369 5.39934C15.1537 4.91611 14.8819 4.26089 14.8813 3.5775V0H3.36374C2.47183 -6.15831e-08 1.61645 0.354224 0.985657 0.984779C0.354867 1.61533 0.00033144 2.4706 0 3.3625V23.64C0.000993838 24.5315 0.355824 25.3861 0.986542 26.0161C1.61726 26.6461 2.47227 27 3.36374 27H17.1888C17.6306 27.0002 18.0681 26.9133 18.4764 26.7443C18.8846 26.5753 19.2556 26.3276 19.5681 26.0152C19.8806 25.7029 20.1285 25.332 20.2976 24.9238C20.4667 24.5156 20.5537 24.0781 20.5537 23.6363V6.155H17.4587Z" fill="#D1E1F6"/>
    <path d="M20.5533 6.155H17.4583C16.775 6.15434 16.1197 5.88257 15.6365 5.39934C15.1533 4.91611 14.8815 4.26089 14.8809 3.5775V0L20.5533 6.155Z" fill="#757575"/>
    <path d="M22.993 21.3252H7.36295C6.25423 21.3252 5.35547 22.224 5.35547 23.3327V27.9927C5.35547 29.1014 6.25423 30.0002 7.36295 30.0002H22.993C24.1017 30.0002 25.0005 29.1014 25.0005 27.9927V23.3327C25.0005 22.224 24.1017 21.3252 22.993 21.3252Z" fill="#233348"/>
    <path d="M9.82407 26.6719V27.5806H8.88281V26.6719H9.82407Z" fill="white"/>
    <path d="M14.5241 23.8486V27.5799H13.6154V25.2499L12.8066 27.5836H12.0204L11.2066 25.2449V27.5836H10.2979V23.8524H11.4091L12.4241 26.4511L13.4241 23.8524L14.5241 23.8486Z" fill="white"/>
    <path d="M16.0601 26.2625V27.58H15.1514V23.8213H16.6251C17.0714 23.8213 17.4126 23.9325 17.6464 24.1538C17.7646 24.27 17.857 24.41 17.9173 24.5644C17.9777 24.7189 18.0046 24.8844 17.9964 25.05C18.0007 25.2685 17.9467 25.4843 17.8401 25.675C17.7309 25.8611 17.5691 26.0107 17.3751 26.105C17.1411 26.2161 16.8841 26.2701 16.6251 26.2625H16.0601ZM17.0701 25.05C17.0701 24.7167 16.8876 24.55 16.5226 24.55H16.0601V25.5288H16.5226C16.8876 25.5321 17.0701 25.3725 17.0701 25.05Z" fill="white"/>
    <path d="M18.3623 26.8893V26.1255L20.0798 23.7705H21.0573V26.0943H21.5098V26.8855H21.0573V27.5768H20.1373V26.8855L18.3623 26.8893ZM20.2123 24.8268L19.2873 26.098H20.2123V24.8268Z" fill="white"/>
    <path d="M14.9298 16.4888V10.525C14.9295 10.4961 14.9266 10.4672 14.9211 10.4388C14.9197 10.4303 14.9176 10.4219 14.9148 10.4138C14.9103 10.3942 14.9045 10.375 14.8973 10.3563C14.8941 10.3477 14.8903 10.3394 14.8861 10.3313C14.8784 10.313 14.8692 10.2954 14.8586 10.2788L14.8461 10.2588C14.8303 10.2361 14.8128 10.2148 14.7936 10.195L14.7848 10.1875C14.7678 10.1715 14.7499 10.1565 14.7311 10.1425L14.7086 10.1288L14.6586 10.1013L14.6323 10.09C14.6128 10.0824 14.5927 10.0762 14.5723 10.0713H14.5523C14.524 10.0653 14.4951 10.0623 14.4661 10.0625H6.04609C5.93037 10.0731 5.82275 10.1265 5.74436 10.2123C5.66597 10.2981 5.62246 10.4101 5.62232 10.5263C5.62232 10.5338 5.62232 10.54 5.62232 10.5475V16.51C5.6193 16.5868 5.64368 16.6621 5.69108 16.7225C5.73208 16.7918 5.79041 16.8492 5.86033 16.8891C5.93024 16.929 6.00933 16.95 6.08982 16.95H14.4648C14.5873 16.9494 14.7046 16.9007 14.7916 16.8144C14.8786 16.7282 14.9282 16.6112 14.9298 16.4888ZM6.12482 11.185V10.28H6.86106V11.185H6.12482ZM7.36732 11.185V10.28H8.10358V11.185H7.36732ZM8.60981 11.185V10.28H9.34607V11.185H8.60981ZM9.85233 11.185V10.28H10.5886V11.185H9.85233ZM11.0948 11.185V10.28H11.8311V11.185H11.0948ZM12.3373 11.185V10.28H13.0736V11.185H12.3373ZM13.5798 11.185V10.28H14.3161V11.185H13.5798ZM5.95481 15.5738V11.43H14.6086V15.5738H5.95481ZM6.12357 16.7225V15.8175H6.85981V16.7225H6.12357ZM7.36606 16.7225V15.8175H8.10233V16.7225H7.36606ZM8.60859 16.7225V15.8175H9.34482V16.7225H8.60859ZM9.85108 16.7225V15.8175H10.5873V16.7225H9.85108ZM11.0936 16.7225V15.8175H11.8298V16.7225H11.0936ZM12.3361 16.7225V15.8175H13.0723V16.7225H12.3361ZM13.5786 16.7225V15.8175H14.3148V16.7225H13.5786Z" fill="#757575"/>
    <path d="M11.2769 13.1953L10.0807 12.5041C10.0188 12.4686 9.94872 12.4501 9.87741 12.4502C9.8061 12.4503 9.73606 12.4692 9.67432 12.5049C9.61257 12.5405 9.56127 12.5918 9.52554 12.6535C9.48981 12.7152 9.4709 12.7853 9.4707 12.8566V14.2391C9.4709 14.3104 9.48981 14.3804 9.52554 14.4421C9.56127 14.5038 9.61257 14.5551 9.67432 14.5908C9.73606 14.6265 9.8061 14.6453 9.87741 14.6454C9.94872 14.6456 10.0188 14.627 10.0807 14.5916L11.2769 13.9003C11.3387 13.8645 11.39 13.8131 11.4256 13.7512C11.4612 13.6894 11.48 13.6192 11.48 13.5478C11.48 13.4764 11.4612 13.4063 11.4256 13.3444C11.39 13.2826 11.3387 13.2311 11.2769 13.1953Z" fill="#757575"/>
    </svg>
  `,
    ZIP: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.458 6.15598C16.7745 6.15499 16.1193 5.88296 15.6362 5.39955C15.153 4.91614 14.8813 4.26082 14.8806 3.57734V0H3.3636C2.47152 0 1.61598 0.354373 0.985179 0.985172C0.354381 1.61597 0 2.47153 0 3.36361V23.6402C0.00132474 24.5314 0.356287 25.3857 0.986942 26.0154C1.6176 26.6451 2.47238 26.9988 3.3636 26.9988H17.1893C18.0805 26.9988 18.9353 26.6451 19.5659 26.0154C20.1966 25.3857 20.5515 24.5314 20.5529 23.6402V6.15598H17.458Z" fill="#D1E1F6"/>
    <path d="M20.5531 6.15598H17.4582C16.7748 6.15499 16.1196 5.88296 15.6364 5.39955C15.1532 4.91614 14.8815 4.26082 14.8809 3.57734V0L20.5531 6.15598Z" fill="#757575"/>
    <path d="M22.9912 21.3252H7.36191C6.25324 21.3252 5.35449 22.2239 5.35449 23.3326V27.9924C5.35449 29.1011 6.25324 29.9998 7.36191 29.9998H22.9912C24.0999 29.9998 24.9986 29.1011 24.9986 27.9924V23.3326C24.9986 22.2239 24.0999 21.3252 22.9912 21.3252Z" fill="#233348"/>
    <path d="M11.5437 26.6709V27.5796H10.6025V26.6709H11.5437Z" fill="white"/>
    <path d="M12.9562 26.8462H14.6149V27.5799H11.9463V26.8887L13.5787 24.55H11.9675V23.8213H14.6049V24.5125L12.9562 26.8462Z" fill="white"/>
    <path d="M16.0827 23.8213V27.5799H15.1777V23.8213H16.0827Z" fill="white"/>
    <path d="M17.6187 26.2612V27.5799H16.71V23.8213H18.1774C18.6236 23.8213 18.9649 23.9313 19.1986 24.1538C19.3169 24.2698 19.4094 24.4094 19.47 24.5637C19.5305 24.7179 19.5577 24.8832 19.5498 25.0487C19.5538 25.2674 19.4994 25.4831 19.3923 25.6737C19.2832 25.8601 19.1215 26.0102 18.9274 26.1049C18.6931 26.2151 18.4362 26.2686 18.1774 26.2612H17.6187ZM18.6286 25.0487C18.6286 24.7196 18.4461 24.5529 18.0811 24.5488H17.6187V25.5262H18.0811C18.4461 25.5304 18.6286 25.3712 18.6286 25.0487Z" fill="white"/>
    <path d="M10.2744 19.064C9.85402 19.0633 9.45108 18.8959 9.15396 18.5986C8.85683 18.3012 8.68978 17.8981 8.68945 17.4778V15.8916H11.8606V17.4778C11.8602 17.8984 11.693 18.3016 11.3956 18.599C11.0982 18.8964 10.695 19.0636 10.2744 19.064ZM9.50067 16.7028V17.4778C9.50067 17.6831 9.58214 17.88 9.7272 18.0253C9.87227 18.1706 10.0691 18.2524 10.2744 18.2528C10.4798 18.2524 10.6767 18.1707 10.822 18.0254C10.9673 17.8801 11.049 17.6832 11.0493 17.4778V16.7028H9.50067Z" fill="#757575"/>
    <path d="M8.68839 15.8936H10.2783V14.3036H8.68839V15.8936Z" fill="#757575"/>
    <path d="M10.2743 14.3047H11.8643V12.7148H10.2743V14.3047Z" fill="#757575"/>
    <path d="M8.68839 12.7158H10.2783V11.1259H8.68839V12.7158Z" fill="#757575"/>
    <path d="M10.2743 11.127H11.8643V9.53703H10.2743V11.127Z" fill="#757575"/>
    </svg>  
  `,
    DOC: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.458 6.15599C16.7747 6.15533 16.1195 5.88356 15.6363 5.40035C15.1531 4.91714 14.8813 4.26195 14.8806 3.57859V2.32308e-07H3.36487C2.92305 -0.000163952 2.48551 0.0867022 2.07728 0.255666C1.66904 0.42463 1.2981 0.672375 0.985631 0.984731C0.673159 1.29709 0.425285 1.66794 0.256169 2.07612C0.0870538 2.48429 -3.05062e-08 2.92178 0 3.3636V23.6403C-3.05062e-08 24.0821 0.0870538 24.5196 0.256169 24.9278C0.425285 25.3359 0.673159 25.7068 0.985631 26.0192C1.2981 26.3315 1.66904 26.5792 2.07728 26.7482C2.48551 26.9172 2.92305 27.004 3.36487 27.0039H17.1893C17.631 27.0039 18.0684 26.9169 18.4765 26.7479C18.8846 26.5788 19.2554 26.331 19.5677 26.0187C19.8801 25.7064 20.1278 25.3356 20.2969 24.9275C20.4659 24.5194 20.5529 24.082 20.5529 23.6403V6.15599H17.458Z" fill="#D1E1F6"/>
    <path d="M20.5531 6.15599H17.4583C16.7749 6.15533 16.1197 5.88356 15.6365 5.40035C15.1533 4.91714 14.8815 4.26195 14.8809 3.57859V0L20.5531 6.15599Z" fill="#757575"/>
    <path d="M16.3241 10.4655H3.41963C3.29565 10.4655 3.17675 10.4162 3.08908 10.3286C3.00141 10.2409 2.95215 10.122 2.95215 9.99803C2.95199 9.93653 2.96396 9.87561 2.98738 9.81874C3.0108 9.76188 3.04521 9.71019 3.08863 9.66665C3.13206 9.62311 3.18365 9.58857 3.24045 9.565C3.29725 9.54143 3.35813 9.5293 3.41963 9.5293H16.3241C16.4484 9.5293 16.5676 9.57869 16.6555 9.66659C16.7434 9.75449 16.7928 9.87371 16.7928 9.99803C16.7925 10.1221 16.743 10.241 16.6551 10.3287C16.5672 10.4163 16.4482 10.4655 16.3241 10.4655Z" fill="#757575"/>
    <path d="M16.3241 13.2614H3.41963C3.29565 13.2614 3.17675 13.2121 3.08908 13.1245C3.00141 13.0368 2.95215 12.9179 2.95215 12.7939C2.95199 12.7324 2.96396 12.6715 2.98738 12.6146C3.0108 12.5578 3.04521 12.5061 3.08863 12.4625C3.13206 12.419 3.18365 12.3845 3.24045 12.3609C3.29725 12.3373 3.35813 12.3252 3.41963 12.3252H16.3241C16.4484 12.3252 16.5676 12.3746 16.6555 12.4625C16.7434 12.5504 16.7928 12.6696 16.7928 12.7939C16.7925 12.918 16.743 13.0369 16.6551 13.1245C16.5672 13.2122 16.4482 13.2614 16.3241 13.2614Z" fill="#757575"/>
    <path d="M16.3241 16.0583H3.41963C3.35813 16.0583 3.29725 16.0461 3.24045 16.0226C3.18365 15.999 3.13206 15.9645 3.08863 15.9209C3.04521 15.8774 3.0108 15.8257 2.98738 15.7688C2.96396 15.712 2.95199 15.651 2.95215 15.5895C2.95215 15.4656 3.00141 15.3467 3.08908 15.259C3.17675 15.1713 3.29565 15.1221 3.41963 15.1221H16.3241C16.4482 15.1221 16.5672 15.1713 16.6551 15.2589C16.743 15.3465 16.7925 15.4655 16.7928 15.5895C16.7928 15.7139 16.7434 15.8331 16.6555 15.921C16.5676 16.0089 16.4484 16.0583 16.3241 16.0583Z" fill="#757575"/>
    <path d="M11.2343 18.8542H3.41963C3.35813 18.8542 3.29725 18.842 3.24045 18.8185C3.18365 18.7949 3.13206 18.7603 3.08863 18.7168C3.04521 18.6733 3.0108 18.6216 2.98738 18.5647C2.96396 18.5079 2.95199 18.4469 2.95215 18.3854C2.95215 18.2615 3.00141 18.1425 3.08908 18.0549C3.17675 17.9672 3.29565 17.918 3.41963 17.918H11.2343C11.3583 17.918 11.4772 17.9672 11.5649 18.0549C11.6525 18.1425 11.7018 18.2615 11.7018 18.3854C11.702 18.4469 11.69 18.5079 11.6666 18.5647C11.6432 18.6216 11.6087 18.6733 11.5653 18.7168C11.5219 18.7603 11.4703 18.7949 11.4135 18.8185C11.3567 18.842 11.2958 18.8542 11.2343 18.8542Z" fill="#757575"/>
    <path d="M22.9922 21.3252H7.36288C6.25421 21.3252 5.35547 22.2239 5.35547 23.3326V27.9924C5.35547 29.1011 6.25421 29.9998 7.36288 29.9998H22.9922C24.1009 29.9998 24.9996 29.1011 24.9996 27.9924V23.3326C24.9996 22.2239 24.1009 21.3252 22.9922 21.3252Z" fill="#233348"/>
    <path d="M9.52715 26.6719V27.5806H8.58594V26.6719H9.52715Z" fill="white"/>
    <path d="M13.1449 26.6743C12.9884 26.9585 12.752 27.1906 12.4649 27.3418C12.1419 27.507 11.7827 27.5891 11.4199 27.5805H10V23.822H11.4199C11.783 23.813 12.1426 23.8932 12.4674 24.0557C12.7533 24.2041 12.989 24.4337 13.1449 24.7157C13.3065 25.0157 13.3879 25.3524 13.3811 25.6931C13.3875 26.035 13.3062 26.3728 13.1449 26.6743ZM12.1674 26.4856C12.269 26.3813 12.3477 26.2569 12.3985 26.1205C12.4493 25.984 12.4711 25.8385 12.4624 25.6931C12.4711 25.548 12.4494 25.4026 12.3986 25.2663C12.3478 25.13 12.2691 25.0059 12.1674 24.9019C11.9397 24.7022 11.6423 24.6007 11.34 24.6194H10.9112V26.7668H11.3412C11.6428 26.785 11.9395 26.684 12.1674 26.4856Z" fill="white"/>
    <path d="M16.6478 24.0108C16.9337 24.1732 17.1701 24.4105 17.3315 24.697C17.5006 24.9997 17.5868 25.3416 17.5815 25.6882C17.5872 26.0357 17.5009 26.3785 17.3315 26.6819C17.1696 26.9696 16.9323 27.2077 16.6453 27.3707C16.3531 27.5375 16.0218 27.6238 15.6853 27.6207C15.3488 27.6236 15.0176 27.5374 14.7253 27.3707C14.4383 27.208 14.2014 26.9698 14.0404 26.6819C13.8703 26.3788 13.7841 26.0358 13.7904 25.6882C13.7839 25.3415 13.8702 24.9993 14.0404 24.697C14.2022 24.4104 14.439 24.1732 14.7253 24.0108C15.0176 23.8441 15.3488 23.7579 15.6853 23.7608C16.0226 23.7577 16.3547 23.8439 16.6478 24.0108ZM14.9728 24.8983C14.7884 25.1188 14.6946 25.4012 14.7103 25.6882C14.6946 25.9742 14.7884 26.2553 14.9728 26.4745C15.0626 26.5721 15.1727 26.6488 15.2954 26.6994C15.4181 26.7499 15.5503 26.7729 15.6828 26.7669C15.8149 26.7726 15.9466 26.7494 16.0688 26.6988C16.191 26.6483 16.3007 26.5717 16.3903 26.4745C16.5755 26.2557 16.6703 25.9745 16.6553 25.6882C16.6703 25.4024 16.5765 25.1215 16.3928 24.902C16.303 24.8043 16.1929 24.7275 16.0702 24.677C15.9476 24.6265 15.8153 24.6034 15.6828 24.6095C15.5506 24.6034 15.4187 24.6261 15.2961 24.676C15.1735 24.7258 15.0632 24.8017 14.9728 24.8983Z" fill="white"/>
    <path d="M20.997 24.161C21.314 24.4189 21.5297 24.7806 21.6058 25.1822H20.6433C20.5784 25.0158 20.4647 24.8729 20.3171 24.7722C20.1615 24.6709 19.9789 24.6191 19.7934 24.6235C19.6708 24.6195 19.549 24.6439 19.4373 24.6946C19.3257 24.7453 19.2273 24.8211 19.1496 24.916C18.9756 25.1386 18.8869 25.4161 18.8996 25.6984C18.8869 25.9796 18.9756 26.256 19.1496 26.4772C19.2279 26.5711 19.3266 26.6459 19.4381 26.6959C19.5497 26.746 19.6712 26.7699 19.7934 26.7659C19.979 26.7708 20.1617 26.7189 20.3171 26.6171C20.4638 26.5183 20.5775 26.3776 20.6433 26.2134H21.6058C21.5284 26.6138 21.313 26.9743 20.997 27.2321C20.657 27.4917 20.237 27.6244 19.8096 27.6071C19.4728 27.6142 19.1403 27.5304 18.8471 27.3646C18.5727 27.2051 18.3501 26.9699 18.2059 26.6871C18.051 26.377 17.9738 26.0338 17.9809 25.6872C17.9739 25.3406 18.0511 24.9974 18.2059 24.6872C18.3501 24.4045 18.5727 24.1693 18.8471 24.0098C19.1403 23.8439 19.4728 23.7602 19.8096 23.7672C20.2392 23.7559 20.6593 23.8952 20.997 24.161Z" fill="white"/>
    </svg>  
  `,
    XLS: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.458 6.15599C16.7747 6.15533 16.1195 5.88356 15.6363 5.40035C15.1531 4.91714 14.8813 4.26195 14.8806 3.57859V0H3.36363C2.47154 0 1.61599 0.354389 0.985188 0.985189C0.354389 1.61599 0 2.47151 0 3.3636V23.6403C0 24.5323 0.354389 25.3879 0.985188 26.0187C1.61599 26.6495 2.47154 27.0039 3.36363 27.0039H17.188C18.0801 27.0039 18.9357 26.6495 19.5665 26.0187C20.1973 25.3879 20.5517 24.5323 20.5517 23.6403V6.15599H17.458Z" fill="#D1E1F6"/>
    <path d="M22.9913 21.3252H7.36192C6.25325 21.3252 5.35449 22.2239 5.35449 23.3326V27.9924C5.35449 29.1011 6.25325 29.9998 7.36192 29.9998H22.9913C24.0999 29.9998 24.9987 29.1011 24.9987 27.9924V23.3326C24.9987 22.2239 24.0999 21.3252 22.9913 21.3252Z" fill="#233348"/>
    <path d="M10.9285 26.6719V27.5806H9.9873V26.6719H10.9285Z" fill="white"/>
    <path d="M13.682 27.5799L12.8908 26.4099L12.2096 27.5799H11.1684L12.3695 25.6449L11.1309 23.8213H12.2096L12.9808 24.9537L13.6458 23.8213H14.682L13.497 25.7137L14.762 27.5799H13.682Z" fill="white"/>
    <path d="M17.6622 27.0697C17.5544 27.2423 17.3996 27.3806 17.216 27.4684C16.9925 27.5731 16.7477 27.624 16.501 27.6171C16.1289 27.6327 15.7622 27.5241 15.4586 27.3084C15.3243 27.2076 15.2144 27.0779 15.1371 26.9288C15.0599 26.7797 15.0172 26.6151 15.0123 26.4472H15.9798C15.9867 26.5738 16.0406 26.6933 16.131 26.7822C16.221 26.8661 16.3405 26.911 16.4635 26.9072C16.5136 26.9101 16.5638 26.9031 16.6112 26.8866C16.6586 26.8701 16.7023 26.8444 16.7398 26.8109C16.7731 26.7786 16.7993 26.7397 16.8168 26.6966C16.8342 26.6536 16.8424 26.6074 16.841 26.5609C16.8422 26.5169 16.8346 26.473 16.8185 26.432C16.8024 26.391 16.7781 26.3536 16.7472 26.3222C16.6811 26.2552 16.6026 26.2018 16.516 26.1647C16.4252 26.1247 16.2977 26.0759 16.1335 26.0185C15.9343 25.9528 15.7397 25.8739 15.5511 25.7822C15.396 25.7023 15.2629 25.5856 15.1636 25.4422C15.0483 25.2687 14.9913 25.0629 15.0011 24.8548C14.9966 24.6485 15.0559 24.4459 15.1711 24.2748C15.2893 24.1075 15.4528 23.9775 15.6423 23.8998C15.8608 23.8109 16.0953 23.7684 16.331 23.7748C16.689 23.7551 17.0426 23.8607 17.331 24.0736C17.4538 24.1734 17.5543 24.2978 17.6262 24.4388C17.6981 24.5798 17.7397 24.7342 17.7485 24.8922H16.7648C16.7538 24.7816 16.7063 24.6779 16.6298 24.5973C16.5907 24.5598 16.5444 24.5307 16.4936 24.5118C16.4429 24.4929 16.3889 24.4845 16.3348 24.4873C16.2411 24.483 16.149 24.5133 16.076 24.5723C16.0424 24.6042 16.0162 24.6431 15.9993 24.6864C15.9825 24.7296 15.9754 24.776 15.9785 24.8223C15.9766 24.9063 16.0081 24.9876 16.066 25.0485C16.1282 25.1134 16.2031 25.165 16.286 25.1998C16.3748 25.2385 16.5023 25.2885 16.6685 25.3485C16.8713 25.4146 17.0689 25.4956 17.2597 25.591C17.4156 25.675 17.5499 25.794 17.6522 25.9385C17.7688 26.1148 17.8266 26.3235 17.8172 26.5347C17.8155 26.7239 17.7619 26.9089 17.6622 27.0697Z" fill="white"/>
    <path d="M19.2278 26.8837H20.4452V27.5799H18.3203V23.8213H19.2303L19.2278 26.8837Z" fill="white"/>
    <path d="M20.5519 6.15599H17.4583C16.7749 6.15533 16.1197 5.88356 15.6365 5.40035C15.1533 4.91714 14.8815 4.26195 14.8809 3.57859V0L20.5519 6.15599Z" fill="#757575"/>
    <path d="M14.8604 18.8547H5.6958C4.90209 18.8547 4.25586 18.0647 4.25586 17.0923V10.4438C4.25586 9.4726 4.90209 8.68262 5.6958 8.68262H14.8642C15.6567 8.68262 16.3029 9.4726 16.3029 10.4438V17.0923C16.2991 18.0647 15.6529 18.8547 14.8604 18.8547ZM5.6958 9.58257C5.30707 9.58257 4.9921 9.96882 4.9921 10.4438V17.0923C4.9921 17.5673 5.30707 17.9535 5.6958 17.9535H14.8642C15.2517 17.9535 15.5666 17.5673 15.5666 17.0923V10.4438C15.5666 9.96882 15.2517 9.58257 14.8642 9.58257H5.6958Z" fill="#757575"/>
    <path d="M15.9319 14.8506H4.62109V15.7518H15.9319V14.8506Z" fill="#757575"/>
    <path d="M15.9319 11.7607H4.62109V12.662H15.9319V11.7607Z" fill="#757575"/>
    <path d="M13.035 9.13184H12.1338V18.4027H13.035V9.13184Z" fill="#757575"/>
    <path d="M8.41879 9.13184H7.51758V18.4027H8.41879V9.13184Z" fill="#757575"/>
    </svg>
    `,
    RAR: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.458 6.15598C16.7745 6.15499 16.1193 5.88296 15.6362 5.39955C15.153 4.91614 14.8813 4.26082 14.8806 3.57734V2.32267e-07H3.36486C2.92304 -0.000163952 2.48551 0.0867173 2.07727 0.255681C1.66904 0.424644 1.29809 0.672373 0.985614 0.984729C0.673142 1.29709 0.425269 1.66794 0.256153 2.07611C0.0870384 2.48429 -3.05062e-08 2.92179 0 3.36361V23.6402C-3.05062e-08 24.082 0.0870384 24.5195 0.256153 24.9277C0.425269 25.3359 0.673142 25.7067 0.985614 26.0191C1.29809 26.3314 1.66904 26.5792 2.07727 26.7482C2.48551 26.9171 2.92304 27.004 3.36486 27.0038H17.1893C18.0813 27.0038 18.9369 26.6494 19.5677 26.0187C20.1985 25.3879 20.5529 24.5323 20.5529 23.6402V6.15598H17.458Z" fill="#D1E1F6"/>
    <path d="M20.5531 6.15598H17.4583C16.7748 6.15499 16.1196 5.88296 15.6364 5.39955C15.1533 4.91614 14.8815 4.26082 14.8809 3.57734V0L20.5531 6.15598Z" fill="#757575"/>
    <path d="M22.9932 21.3252H7.36386C6.25519 21.3252 5.35645 22.2239 5.35645 23.3326V27.9924C5.35645 29.1011 6.25519 29.9998 7.36386 29.9998H22.9932C24.1018 29.9998 25.0006 29.1011 25.0006 27.9924V23.3326C25.0006 22.2239 24.1018 21.3252 22.9932 21.3252Z" fill="#233348"/>
    <path d="M10.2381 26.6709V27.5796H9.29688V26.6709H10.2381Z" fill="white"/>
    <path d="M12.6418 27.5805L11.8281 26.1606H11.6206V27.5805H10.7119V23.8219H12.2644C12.5228 23.8149 12.7795 23.8675 13.0143 23.9757C13.2071 24.0673 13.3681 24.2144 13.4768 24.3981C13.5814 24.586 13.6345 24.7981 13.6305 25.0131C13.6378 25.2644 13.5579 25.5104 13.4043 25.7093C13.2369 25.9114 13.0053 26.05 12.7481 26.1018L13.6418 27.5805H12.6418ZM11.6206 25.5393H12.1844C12.3284 25.5502 12.471 25.5054 12.5831 25.4143C12.6275 25.3674 12.6615 25.3117 12.6831 25.2507C12.7046 25.1898 12.7131 25.125 12.7081 25.0606C12.7122 24.9966 12.7033 24.9325 12.6818 24.8721C12.6603 24.8117 12.6267 24.7564 12.5831 24.7094C12.4726 24.6173 12.3302 24.5724 12.1869 24.5844H11.6231L11.6206 25.5393Z" fill="white"/>
    <path d="M16.4222 26.8938H15.0135L14.7785 27.58H13.8223L15.1972 23.8477H16.2434L17.6184 27.58H16.6509L16.4222 26.8938ZM16.1884 26.2026L15.7197 24.8201L15.2472 26.2026H16.1884Z" fill="white"/>
    <path d="M19.9045 27.5805L19.0908 26.1606H18.8833V27.5805H17.9746V23.8219H19.527C19.7855 23.8149 20.0422 23.8675 20.277 23.9757C20.4698 24.0673 20.6308 24.2144 20.7395 24.3981C20.8442 24.586 20.8972 24.7981 20.8932 25.0131C20.9006 25.2644 20.8206 25.5104 20.667 25.7093C20.4996 25.9114 20.268 26.05 20.0108 26.1018L20.9045 27.5805H19.9045ZM18.8833 25.5393H19.447C19.591 25.5499 19.7336 25.5052 19.8458 25.4143C19.8902 25.3674 19.9242 25.3117 19.9458 25.2507C19.9673 25.1898 19.9758 25.125 19.9708 25.0606C19.9749 24.9966 19.966 24.9325 19.9445 24.8721C19.923 24.8117 19.8894 24.7564 19.8458 24.7094C19.7353 24.6173 19.5929 24.5724 19.4496 24.5844H18.8858L18.8833 25.5393Z" fill="white"/>
    <path d="M11.871 18.8542H4.05635C3.93225 18.8538 3.81335 18.8043 3.72572 18.7165C3.63808 18.6286 3.58887 18.5095 3.58887 18.3854C3.58887 18.2615 3.63812 18.1426 3.72579 18.0549C3.81346 17.9672 3.93236 17.918 4.05635 17.918H11.871C11.9325 17.9178 11.9934 17.9298 12.0503 17.9532C12.1072 17.9766 12.1588 18.011 12.2024 18.0545C12.2459 18.0979 12.2805 18.1495 12.304 18.2063C12.3276 18.2631 12.3397 18.324 12.3397 18.3854C12.3397 18.5098 12.2904 18.629 12.2024 18.7169C12.1145 18.8048 11.9953 18.8542 11.871 18.8542Z" fill="#757575"/>
    <path d="M11.871 16.0925H4.05635C3.93225 16.0921 3.81335 16.0426 3.72572 15.9547C3.63808 15.8669 3.58887 15.7478 3.58887 15.6237C3.5892 15.4998 3.63856 15.3811 3.72616 15.2935C3.81376 15.2059 3.93246 15.1566 4.05635 15.1563H11.871C11.9951 15.1562 12.1142 15.2055 12.202 15.2931C12.2899 15.3807 12.3394 15.4996 12.3397 15.6237C12.3397 15.748 12.2904 15.8673 12.2024 15.9552C12.1145 16.0431 11.9953 16.0925 11.871 16.0925Z" fill="#757575"/>
    <path d="M11.871 13.3317H4.05635C3.93236 13.3317 3.81346 13.2825 3.72579 13.1948C3.63812 13.1071 3.58887 12.9882 3.58887 12.8642C3.58887 12.7401 3.63808 12.6211 3.72572 12.5332C3.81335 12.4454 3.93225 12.3958 4.05635 12.3955H11.871C11.9953 12.3955 12.1145 12.4449 12.2024 12.5328C12.2904 12.6207 12.3397 12.7399 12.3397 12.8642C12.3397 12.9257 12.3276 12.9866 12.304 13.0434C12.2805 13.1002 12.2459 13.1518 12.2024 13.1952C12.1588 13.2387 12.1072 13.2731 12.0503 13.2965C11.9934 13.3199 11.9325 13.3319 11.871 13.3317Z" fill="#757575"/>
    <path d="M11.871 10.5707H4.05635C3.93236 10.5707 3.81346 10.5215 3.72579 10.4338C3.63812 10.3461 3.58887 10.2272 3.58887 10.1032C3.58887 9.97924 3.63812 9.86034 3.72579 9.77267C3.81346 9.685 3.93236 9.63574 4.05635 9.63574H11.871C11.9325 9.63558 11.9934 9.64756 12.0503 9.67097C12.1072 9.69439 12.1588 9.7288 12.2024 9.77223C12.2459 9.81565 12.2805 9.86725 12.304 9.92405C12.3276 9.98085 12.3397 10.0417 12.3397 10.1032C12.3397 10.1647 12.3276 10.2256 12.304 10.2824C12.2805 10.3392 12.2459 10.3908 12.2024 10.4342C12.1588 10.4777 12.1072 10.5121 12.0503 10.5355C11.9934 10.5589 11.9325 10.5709 11.871 10.5707Z" fill="#757575"/>
    <path d="M15.3762 19.064C14.9556 19.0636 14.5524 18.8964 14.255 18.599C13.9576 18.3016 13.7904 17.8984 13.79 17.4778V15.8916H16.9612V17.4778C16.9608 17.8981 16.7938 18.3012 16.4966 18.5986C16.1995 18.8959 15.7966 19.0633 15.3762 19.064ZM14.6013 16.7028V17.4778C14.6016 17.6832 14.6833 17.8801 14.8286 18.0254C14.9739 18.1707 15.1708 18.2524 15.3762 18.2528C15.5817 18.2524 15.7786 18.1707 15.9238 18.0254C16.0691 17.8801 16.1509 17.6832 16.1512 17.4778V16.7028H14.6013Z" fill="#757575"/>
    <path d="M13.7899 15.8936H15.3799V14.3036H13.7899V15.8936Z" fill="#757575"/>
    <path d="M15.3749 14.3047H16.9648V12.7148H15.3749V14.3047Z" fill="#757575"/>
    <path d="M13.7899 12.7158H15.3799V11.1259H13.7899V12.7158Z" fill="#757575"/>
    <path d="M15.3749 11.127H16.9648V9.53703H15.3749V11.127Z" fill="#757575"/>
    </svg>  
  `,
    DOCX: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.458 6.15598C16.7746 6.15532 16.1195 5.88355 15.6362 5.40034C15.153 4.91713 14.8813 4.26194 14.8806 3.57858V2.32307e-07H3.36486C2.92304 -0.000163952 2.48551 0.086702 2.07727 0.255665C1.66904 0.424629 1.29809 0.672374 0.985614 0.98473C0.673142 1.29709 0.425269 1.66794 0.256153 2.07611C0.0870384 2.48429 -3.05062e-08 2.92177 0 3.36359V23.6402C-3.05062e-08 24.082 0.0870384 24.5196 0.256153 24.9277C0.425269 25.3359 0.673142 25.7068 0.985614 26.0191C1.29809 26.3315 1.66904 26.5792 2.07727 26.7481C2.48551 26.9171 2.92304 27.004 3.36486 27.0038H17.1893C18.0813 27.0038 18.9369 26.6495 19.5677 26.0187C20.1985 25.3879 20.5529 24.5323 20.5529 23.6402V6.15598H17.458Z" fill="#D1E1F6"/>
    <path d="M20.5531 6.15598H17.4583C16.7749 6.15532 16.1197 5.88355 15.6365 5.40034C15.1533 4.91713 14.8815 4.26194 14.8809 3.57858V0L20.5531 6.15598Z" fill="#757575"/>
    <path d="M16.3241 10.4655H3.41963C3.29565 10.4655 3.17675 10.4162 3.08908 10.3286C3.00141 10.2409 2.95215 10.122 2.95215 9.99803C2.95199 9.93653 2.96396 9.87561 2.98738 9.81874C3.0108 9.76188 3.04521 9.71019 3.08863 9.66665C3.13206 9.62311 3.18364 9.58857 3.24044 9.565C3.29724 9.54143 3.35813 9.5293 3.41963 9.5293H16.3241C16.4484 9.5293 16.5676 9.57869 16.6555 9.66659C16.7434 9.75449 16.7928 9.87371 16.7928 9.99803C16.7925 10.1221 16.7429 10.241 16.6551 10.3287C16.5672 10.4163 16.4482 10.4655 16.3241 10.4655Z" fill="#757575"/>
    <path d="M16.3241 13.2614H3.41963C3.29565 13.2614 3.17675 13.2121 3.08908 13.1245C3.00141 13.0368 2.95215 12.9179 2.95215 12.7939C2.95199 12.7324 2.96396 12.6715 2.98738 12.6146C3.0108 12.5578 3.04521 12.5061 3.08863 12.4625C3.13206 12.419 3.18364 12.3845 3.24044 12.3609C3.29724 12.3373 3.35813 12.3252 3.41963 12.3252H16.3241C16.4484 12.3252 16.5676 12.3746 16.6555 12.4625C16.7434 12.5504 16.7928 12.6696 16.7928 12.7939C16.7925 12.918 16.7429 13.0369 16.6551 13.1245C16.5672 13.2122 16.4482 13.2614 16.3241 13.2614Z" fill="#757575"/>
    <path d="M16.3241 16.0583H3.41963C3.35813 16.0583 3.29724 16.0461 3.24044 16.0226C3.18364 15.999 3.13206 15.9645 3.08863 15.9209C3.04521 15.8774 3.0108 15.8257 2.98738 15.7688C2.96396 15.712 2.95199 15.651 2.95215 15.5895C2.95215 15.4656 3.00141 15.3467 3.08908 15.259C3.17675 15.1713 3.29565 15.1221 3.41963 15.1221H16.3241C16.4482 15.1221 16.5672 15.1713 16.6551 15.2589C16.7429 15.3465 16.7925 15.4655 16.7928 15.5895C16.7928 15.7139 16.7434 15.8331 16.6555 15.921C16.5676 16.0089 16.4484 16.0583 16.3241 16.0583Z" fill="#757575"/>
    <path d="M11.2318 18.8542H3.41963C3.35813 18.8542 3.29724 18.842 3.24044 18.8185C3.18364 18.7949 3.13206 18.7603 3.08863 18.7168C3.04521 18.6733 3.0108 18.6216 2.98738 18.5647C2.96396 18.5079 2.95199 18.4469 2.95215 18.3854C2.95215 18.2615 3.00141 18.1425 3.08908 18.0549C3.17675 17.9672 3.29565 17.918 3.41963 17.918H11.2318C11.3558 17.918 11.4747 17.9672 11.5623 18.0549C11.65 18.1425 11.6993 18.2615 11.6993 18.3854C11.6994 18.4469 11.6875 18.5079 11.664 18.5647C11.6406 18.6216 11.6062 18.6733 11.5628 18.7168C11.5194 18.7603 11.4678 18.7949 11.411 18.8185C11.3542 18.842 11.2933 18.8542 11.2318 18.8542Z" fill="#757575"/>
    <path d="M22.9932 21.3252H7.36386C6.25519 21.3252 5.35645 22.2239 5.35645 23.3326V27.9924C5.35645 29.1011 6.25519 29.9998 7.36386 29.9998H22.9932C24.1018 29.9998 25.0006 29.1011 25.0006 27.9924V23.3326C25.0006 22.2239 24.1018 21.3252 22.9932 21.3252Z" fill="#233348"/>
    <path d="M7.64725 26.6719V27.5806H6.70605V26.6719H7.64725Z" fill="white"/>
    <path d="M11.265 26.6743C11.1081 26.9582 10.8718 27.1902 10.585 27.3418C10.262 27.507 9.9028 27.589 9.54005 27.5805H8.12012V23.8219H9.54005C9.90306 23.8131 10.2627 23.8934 10.5875 24.0557C10.8734 24.2041 11.1091 24.4337 11.265 24.7156C11.4266 25.0157 11.508 25.3524 11.5012 25.6931C11.5077 26.035 11.4263 26.3728 11.265 26.6743ZM10.2875 26.4856C10.3891 26.3813 10.4679 26.2569 10.5186 26.1205C10.5694 25.984 10.5912 25.8385 10.5825 25.6931C10.5913 25.5479 10.5695 25.4025 10.5187 25.2663C10.4679 25.13 10.3892 25.0059 10.2875 24.9019C10.0598 24.7022 9.76235 24.6007 9.46006 24.6194H9.03008V26.7668H9.46006C9.7621 26.7853 10.0593 26.6843 10.2875 26.4856Z" fill="white"/>
    <path d="M14.7686 24.0108C15.0546 24.1732 15.2909 24.4105 15.4523 24.697C15.6215 24.9997 15.7077 25.3416 15.7023 25.6882C15.7086 26.0358 15.6223 26.3787 15.4523 26.6819C15.2913 26.9698 15.0543 27.208 14.7673 27.3707C14.4751 27.5375 14.1438 27.6238 13.8074 27.6206C13.4709 27.6238 13.1396 27.5375 12.8474 27.3707C12.5604 27.208 12.3235 26.9698 12.1624 26.6819C11.9924 26.3787 11.9061 26.0358 11.9124 25.6882C11.906 25.3415 11.9923 24.9993 12.1624 24.697C12.3243 24.4104 12.5611 24.1732 12.8474 24.0108C13.1396 23.8439 13.4709 23.7577 13.8074 23.7608C14.1442 23.7579 14.4759 23.8441 14.7686 24.0108ZM13.0937 24.8983C12.9093 25.1188 12.8155 25.4012 12.8312 25.6882C12.8154 25.9742 12.9093 26.2553 13.0937 26.4745C13.1835 26.5721 13.2936 26.6488 13.4162 26.6994C13.5389 26.7499 13.6711 26.7729 13.8036 26.7669C13.9357 26.7724 14.0674 26.7491 14.1896 26.6986C14.3117 26.6481 14.4214 26.5716 14.5111 26.4745C14.6964 26.2557 14.7911 25.9745 14.7761 25.6882C14.7911 25.4024 14.6973 25.1215 14.5136 24.902C14.4238 24.8043 14.3137 24.7275 14.1911 24.677C14.0684 24.6265 13.9361 24.6034 13.8036 24.6095C13.6714 24.6034 13.5395 24.6261 13.4169 24.676C13.2944 24.7258 13.1841 24.8017 13.0937 24.8983Z" fill="white"/>
    <path d="M19.1164 24.161C19.4334 24.4189 19.649 24.7806 19.7251 25.1822H18.7627C18.6977 25.0158 18.584 24.8729 18.4364 24.7722C18.2809 24.6709 18.0983 24.6191 17.9127 24.6235C17.7902 24.6195 17.6684 24.6439 17.5567 24.6946C17.4451 24.7453 17.3466 24.8211 17.269 24.916C17.095 25.1386 17.0064 25.4162 17.019 25.6984C17.0063 25.9796 17.095 26.2559 17.269 26.4772C17.3473 26.5711 17.4459 26.6459 17.5575 26.6959C17.669 26.746 17.7905 26.7699 17.9127 26.7659C18.0984 26.7708 18.2811 26.7189 18.4364 26.6171C18.5832 26.5183 18.6969 26.3776 18.7627 26.2134H19.7251C19.6478 26.6138 19.4323 26.9743 19.1164 27.2321C18.7764 27.4917 18.3564 27.6243 17.929 27.6071C17.5922 27.6142 17.2597 27.5304 16.9665 27.3646C16.6921 27.2051 16.4695 26.9699 16.3253 26.6871C16.17 26.3771 16.0923 26.0339 16.099 25.6872C16.0924 25.3405 16.17 24.9973 16.3253 24.6872C16.4695 24.4045 16.6921 24.1693 16.9665 24.0098C17.2597 23.8439 17.5922 23.7602 17.929 23.7672C18.3586 23.7559 18.7787 23.8952 19.1164 24.161Z" fill="white"/>
    <path d="M22.517 27.5799L21.7245 26.4099L21.0445 27.5799H20.0021L21.2045 25.6449L19.9658 23.8213H21.0445L21.8157 24.9537L22.4795 23.8213H23.5169L22.3307 25.7137L23.5957 27.5799H22.517Z" fill="white"/>
    </svg>  
  `,
    CSV: `<svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.458 6.15599C16.7747 6.15533 16.1195 5.88356 15.6363 5.40035C15.1531 4.91714 14.8813 4.26195 14.8806 3.57859V0H3.36361C2.47153 0 1.61598 0.354389 0.985179 0.985188C0.35438 1.61599 0 2.47151 0 3.3636V23.6403C0.00132474 24.5315 0.356289 25.3857 0.986945 26.0155C1.6176 26.6452 2.47239 26.9989 3.36361 26.9989H17.188C17.6299 26.999 18.0674 26.9122 18.4756 26.7432C18.8839 26.5742 19.2548 26.3265 19.5673 26.0141C19.8797 25.7018 20.1276 25.3309 20.2967 24.9228C20.4659 24.5146 20.5529 24.0771 20.5529 23.6353V6.15099L17.458 6.15599Z" fill="#D1E1F6"/>
    <path d="M20.5531 6.15599H17.4583C16.7749 6.15533 16.1197 5.88356 15.6365 5.40035C15.1533 4.91714 14.8815 4.26195 14.8809 3.57859V0L20.5531 6.15599Z" fill="#757575"/>
    <path d="M22.9913 21.3252H7.36191C6.25324 21.3252 5.35449 22.2239 5.35449 23.3326V27.9924C5.35449 29.1011 6.25324 29.9998 7.36191 29.9998H22.9913C24.0999 29.9998 24.9987 29.1011 24.9987 27.9924V23.3326C24.9987 22.2239 24.0999 21.3252 22.9913 21.3252Z" fill="#233348"/>
    <path d="M10.0602 26.6387V27.5449H9.12402V26.6387H10.0602Z" fill="white"/>
    <path d="M13.4243 24.1421C13.7407 24.3981 13.9556 24.7583 14.0306 25.1583H13.0731C13.0092 24.9923 12.8963 24.8497 12.7494 24.7495C12.5948 24.6482 12.413 24.5963 12.2281 24.6008C12.1063 24.5971 11.9852 24.6214 11.8743 24.6719C11.7633 24.7224 11.6654 24.7977 11.5882 24.892C11.4164 25.1141 11.3299 25.3904 11.3444 25.6708C11.3298 25.95 11.4164 26.2251 11.5882 26.4457C11.6657 26.5395 11.7637 26.6143 11.8747 26.6643C11.9856 26.7144 12.1065 26.7383 12.2281 26.7344C12.413 26.739 12.5948 26.6871 12.7494 26.5857C12.8963 26.4876 13.01 26.3472 13.0756 26.1832H14.0331C13.9565 26.5819 13.7418 26.9408 13.4268 27.197C13.0889 27.4568 12.6703 27.5895 12.2444 27.5719C11.9089 27.5793 11.5777 27.4959 11.2857 27.3307C11.0121 27.1725 10.7905 26.9379 10.6482 26.6557C10.4942 26.3493 10.4169 26.01 10.4232 25.667C10.4168 25.3237 10.494 24.9839 10.6482 24.677C10.7905 24.3948 11.0121 24.1603 11.2857 24.0021C11.5777 23.8368 11.9089 23.7535 12.2444 23.7608C12.6702 23.7462 13.0876 23.8811 13.4243 24.1421Z" fill="white"/>
    <path d="M17.0777 27.0365C16.9699 27.2084 16.8156 27.3462 16.6327 27.4339C16.41 27.5382 16.166 27.5887 15.9202 27.5814C15.5501 27.5964 15.1854 27.4888 14.8828 27.2752C14.7491 27.1747 14.6397 27.0455 14.5627 26.8971C14.4857 26.7487 14.443 26.5849 14.4378 26.4178H15.4015C15.4082 26.5437 15.4622 26.6624 15.5527 26.7502C15.6423 26.8341 15.7614 26.879 15.884 26.8752C15.9845 26.8803 16.083 26.8463 16.1589 26.7802C16.1919 26.7477 16.2178 26.7086 16.235 26.6656C16.2522 26.6226 16.2603 26.5765 16.2589 26.5302C16.2601 26.4865 16.2525 26.4429 16.2366 26.4022C16.2208 26.3614 16.1969 26.3242 16.1664 26.2928C16.1008 26.2261 16.0226 26.173 15.9365 26.1365C15.844 26.0953 15.7177 26.0465 15.5552 25.9903C15.3569 25.925 15.1631 25.8465 14.9752 25.7553C14.8209 25.6754 14.6883 25.5592 14.589 25.4165C14.4748 25.2435 14.4184 25.0387 14.4278 24.8315C14.4227 24.6262 14.4816 24.4244 14.5965 24.2541C14.7146 24.0877 14.877 23.9578 15.0652 23.8791C15.283 23.791 15.5166 23.7484 15.7515 23.7541C16.1066 23.7353 16.4572 23.84 16.7439 24.0503C16.8663 24.1495 16.9665 24.2734 17.038 24.4137C17.1095 24.5541 17.1507 24.708 17.1589 24.8653H16.1802C16.1682 24.7554 16.1208 24.6523 16.0452 24.5716C16.0061 24.5346 15.9599 24.5058 15.9095 24.4871C15.859 24.4685 15.8052 24.4602 15.7515 24.4628C15.658 24.458 15.5662 24.4883 15.494 24.5478C15.4602 24.5796 15.4339 24.6186 15.417 24.6618C15.4002 24.7051 15.3931 24.7515 15.3965 24.7978C15.3954 24.8392 15.4026 24.8805 15.4176 24.9191C15.4326 24.9577 15.4552 24.993 15.484 25.0228C15.5459 25.088 15.6209 25.1395 15.704 25.174C15.7915 25.2128 15.919 25.2628 16.079 25.3228C16.281 25.3878 16.4778 25.468 16.6677 25.5628C16.8234 25.6465 16.9574 25.7655 17.0589 25.9103C17.1748 26.0855 17.2321 26.2929 17.2227 26.5027C17.2236 26.6905 17.1735 26.875 17.0777 27.0365Z" fill="white"/>
    <path d="M18.4287 23.8027L19.3449 26.6189L20.2611 23.8027H21.2298L19.9274 27.5451H18.7574L17.46 23.8027H18.4287Z" fill="white"/>
    <path d="M14.861 18.8489H5.6926C4.89888 18.8489 4.25391 18.0589 4.25391 17.0864V10.4379C4.25391 9.46674 4.89888 8.67676 5.6926 8.67676H14.861C15.6547 8.67676 16.2997 9.46674 16.2997 10.4379V17.0864C16.2997 18.0589 15.6547 18.8489 14.861 18.8489ZM5.6926 9.57674C5.30511 9.57674 4.98888 9.96297 4.98888 10.4379V17.0864C4.98888 17.5614 5.30511 17.9476 5.6926 17.9476H14.861C15.2485 17.9476 15.5634 17.5614 15.5634 17.0864V10.4379C15.5634 9.96297 15.2485 9.57674 14.861 9.57674H5.6926Z" fill="#757575"/>
    <path d="M15.9319 14.8457H4.62109V15.7469H15.9319V14.8457Z" fill="#757575"/>
    <path d="M15.9319 11.7559H4.62109V12.6571H15.9319V11.7559Z" fill="#757575"/>
    <path d="M13.036 9.12695H12.1348V18.3978H13.036V9.12695Z" fill="#757575"/>
    <path d="M8.41879 9.12695H7.51758V18.3978H8.41879V9.12695Z" fill="#757575"/>
    </svg>  
  `,
    FILE: `<svg class="a-s-fa-Ha-pa c-qd a-Mg-qd-c" width="24px" height="24px" viewBox="0 0 24 24"
    fill="#757575" focusable="false">
    <path
        d="M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM19 20H5V19H19V20ZM19 17H5V4H19V17Z"></path>
    <path
        d="M13.1215 6H10.8785C10.5514 6 10.271 6.18692 10.0841 6.46729L7.14019 11.6075C7 11.8878 7 12.215 7.14019 12.4953L8.26168 14.4579C8.40187 14.7383 8.72897 14.9252 9.05608 14.9252H15.0374C15.3645 14.9252 15.6449 14.7383 15.8318 14.4579L16.9533 12.4953C17.0935 12.215 17.0935 11.8878 16.9533 11.6075L13.9159 6.46729C13.7757 6.18692 13.4486 6 13.1215 6ZM10.1776 12.0748L12.0467 8.8972L13.8692 12.0748H10.1776Z"></path>
  </svg>
  `,
  };
  