
import { Container, DeleteButton, FileIcon, FileInfo, FileName, FilePreview, ImagePreview, ImagePreviewWrapper } from "./styled";
import { CloseOutlined } from "@ant-design/icons";
import { FileItemProps } from "./types";
import Text from "../../../Text/Text";
import { FontSize } from "../../../../utils/Fonts";
import { getFileIcon } from "../../../../utils/files";
import { NewText } from "../../../NewText/Text";
import { TEXT_SIZE } from "../../../NewText/Font";

export default function FileItem(props: FileItemProps) {

  const { file, onDelete, key } = props;

  const isImage =
    file?.type?.toLowerCase() === "image"

  return (
    <Container>
      {isImage && (
        <ImagePreviewWrapper>
          <ImagePreview
            src={file.attachment.payload.url}
            alt={file?.name}
            key={key}
          />
          <DeleteButton>
          <CloseOutlined onClick={() => onDelete(file.uid)}/>
          </DeleteButton>
        </ImagePreviewWrapper>
      )}
      {!isImage && (
        <FilePreview key={key}>
          <FileIcon>{getFileIcon(file?.extension)}</FileIcon>
          <FileInfo>
            <FileName>
              <NewText 
                value={file?.name}
                fontColor="#000"
                fontSize={TEXT_SIZE.XS}
                lineCount={1}
              />
            </FileName>
            <Text
              value={file?.extension?? ''}
              fontSize={FontSize.TEXT_XS}
              fontColor="#000"
            />
          </FileInfo>
          <DeleteButton>
          <CloseOutlined onClick={() => onDelete(file.uid)}/>
          </DeleteButton>
        </FilePreview>
      )}
    </Container>
  );
}
