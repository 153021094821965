import { FONT_WEIGHT, TEXT_SIZE } from "./Font";
import { Container } from "./Styled";
import { TextProps } from "./types";

/**
 * Renders a text component with the given text properties.
 *
 * @param {TextProps} textProps - the properties for the text component
 * @return {JSX.Element} the rendered text component
 */
export function NewText(textProps: TextProps) {
  const { value, fontSize, fontWeight, fontColor} = textProps;

  const textSize = fontSize ?? TEXT_SIZE.XS;

  const textWeight = fontWeight ?? FONT_WEIGHT.EXTRALIGHT;

  const className = `${textSize} ${textWeight}`;
 
  return (
    <Container
      suppressHydrationWarning
      $fontColor={fontColor}
      className={className}
    >
      {value}
    </Container>
  );
}
