import { Configuration } from "../../domain/adapters/configuration";

interface TrasformConfigurationProps {
  configuration: Configuration;
  externalUser: any;
}

const EXTERNAL_VARIABLES_STORED = ["name", "lastName"];

export const getExternalLoggedUser = () => {

  let user: any = {};

  for (let index = 0; index < EXTERNAL_VARIABLES_STORED.length; index++) {
    const variableName = EXTERNAL_VARIABLES_STORED[index];

    const value = localStorage.getItem(variableName);

    if (value) {
      user[variableName] = value;
    }
  }

  if (Object.keys(user).length === 0) return null;

  return user;
};

function replaceVariableValues(props: {
  variableName: string;
  value: string;
  text: string;
}) {
  const { variableName, value, text } = props;
  // Use a regular expression with the global flag to replace all occurrences
  var regex = new RegExp(`{{${variableName}}}`, "g");
  return text.replace(regex, value);
}

export const trasformConfiguration = (props: TrasformConfigurationProps) => {
  const { configuration, externalUser } = props;

  if (!configuration) return;

  let initialMessage = configuration?.initialMessage;

  if (!initialMessage) return;

  Object.keys(externalUser).forEach((variableName) => {
    if (externalUser[variableName] && initialMessage) {
      initialMessage = replaceVariableValues({
        variableName: variableName,
        value: externalUser[variableName],
        text: initialMessage,
      });
    }
  });

  if (!initialMessage) return;

  const updatedConfig = { ...configuration, initialMessage };

  return updatedConfig;
};
