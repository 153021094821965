import styled from "styled-components";
import { GroupMultimediaProps } from "./types";
import { Attachment } from "../../hooks/socket/types";

const Container = styled.div`
  display: grid;
  position: relative;

  padding: 6px;
  padding-bottom: 0;
  gap: 6px;
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
  font-family: "DM Sans", sans-serif !important;
`;

const ImageWrapper = styled.div<{ imageUrl: string }>`
  width: 108px;
  height: 108px;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const ButtonShowMultimedia = styled.div`
  border-radius: 0.5rem;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.3);
  position: absolute;
  right: 6px;
  bottom: 0;
  font-size: 3rem; /* 48px */
  line-height: 1;
  color: #dadada;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;

  :hover {
    background-color: rgba(34, 34, 34, 0.4);
    color: #ebebeb;
  }
`;

export default function GroupMultimedia(
  groupMultimediaProps: GroupMultimediaProps
) {
  const { groupMultimedia, handleSelectedAttachment, handleAttachments } =
    groupMultimediaProps;

  const firstFourElements = groupMultimedia.slice(0, 4);

  const handleShowFiles = (multimedia?: Attachment) => {
    handleAttachments(groupMultimedia);

    if (multimedia) {
      handleSelectedAttachment(multimedia);
      return;
    }
    handleSelectedAttachment(groupMultimedia[0]);
  };

  return (
    <Container>
      {firstFourElements.map((multimedia, index) => (
        <ImageWrapper
          key={index}
          onClick={() => {
            handleShowFiles(multimedia);
          }}
          imageUrl={multimedia.payload.url}
        />
      ))}
      <ButtonShowMultimedia onClick={() => handleShowFiles()}>
        +{groupMultimedia.length}
      </ButtonShowMultimedia>
    </Container>
  );
}
