import { Button } from "antd";
import styled from "styled-components";

export const Container = styled(Button)<{
  $backgroundColor?: string;
  $hoveredBackgroundColor?: string;
  $hoveredFontColor?: string;
  $disabled?: boolean;
}>`
  background-color: ${(props) => props.$backgroundColor};
  height: 2.25rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  padding: 4px 8px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => props.$hoveredBackgroundColor} !important;
    cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};

    & div {
      color: ${(props) => props.$hoveredFontColor || ""} !important;
    }
  }
`;
