const theme = {
    colors: {
      'primary-light': '#00a7eb',
      primary: '#1967d2',
      'primary-dark': '#233348',
      'secondary-light': '#adde00',
      'secondary-dark': '#65c200',
      tertiary: '#d1e1f6',
      white: '#FFFFFF',
      'white-sm': 'rgba(255, 255, 255, 0.7)',
      plate: 'rgb(242, 242, 242)',
      black: '#222',
    },
    background: {
      'primary-light': '#00a7eb',
      primary: '#1967d2',
      dark: '#233348',
      'secondary-light': '#adde00',
      'secondary-dark': '#65c200',
      tertiary: '#d1e1f6',
      white: '#FFFFFF',
      'white-sm': 'rgba(255, 255, 255, 0.7)',
      plate: 'rgb(242, 242, 242)',
      black: '#333333',
    },
  };
  
  export default theme;
  