import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const TRANSPORT_WEBSOCKET = "websocket";

const useSocket = (
  connectionId?: string | null,
  contactId?: string
): Socket | null => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!connectionId || !contactId) return;

    const newSocket = io(process.env.REACT_APP_API_URL ?? "", {
      path: process.env.REACT_APP_URL_SOCKET,
      transports: [TRANSPORT_WEBSOCKET],
      query: {
        connectionId,
        contactId,
      },
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [connectionId, contactId]);

  return socket;
};

export default useSocket;
