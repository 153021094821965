export enum FontSize {
  TEXT_XXS,
  TEXT_XS,
  TEXT_SM,
  TEXT_BASE,
  TEXT_LG,
  TEXT_XL,
  TEXT_2XL,
  TEXT_3XL,
  TEXT_4XL,
}
export function getFontSizeAndLineHeight(fontSize?: FontSize): {
  fontSize: string;
  lineHeight: string;
} {
  switch (fontSize) {
    case FontSize.TEXT_XXS:
      return { fontSize: "0.6875rem", lineHeight: ".75rem" };
    case FontSize.TEXT_XS:
      return { fontSize: "0.75rem", lineHeight: "1rem" };
    case FontSize.TEXT_SM:
      return { fontSize: "0.875rem", lineHeight: "1.25rem" };
    case FontSize.TEXT_BASE:
      return { fontSize: "1rem", lineHeight: "1.5rem" };
    case FontSize.TEXT_LG:
      return { fontSize: "1.125rem", lineHeight: "1.75rem" };
    case FontSize.TEXT_XL:
      return { fontSize: "1.25rem", lineHeight: "1.75rem" };
    case FontSize.TEXT_2XL:
      return { fontSize: "1.5rem", lineHeight: "2rem" };
    case FontSize.TEXT_3XL:
      return { fontSize: "1.875rem", lineHeight: "2.25rem" };
    case FontSize.TEXT_4XL:
      return { fontSize: "2.25rem", lineHeight: "2.5rem" };
    default:
      return { fontSize: "1rem", lineHeight: "1.5rem" };
  }
}

export enum FontWeight {
  FONT_NORMAL,
  FONT_SEMIBOLD,
  FONT_BOLD,
}

export function getFontWeight(fontWeight?: FontWeight): string {
  switch (fontWeight) {
    case FontWeight.FONT_NORMAL:
      return "400";
    case FontWeight.FONT_SEMIBOLD:
      return "600";
    case FontWeight.FONT_BOLD:
      return "700";
    default:
      return "400";
  }
}
