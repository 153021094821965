
import SVG from "react-inlinesvg";
import { ICONS } from "../icons/icons";

export type GetIcons = {
  [key: string]: JSX.Element;
}

const fileIcons: GetIcons = {
  pdf: <SVG src={ICONS.PDF} />,
  doc: <SVG src={ICONS.DOC} />,
  docx: <SVG src={ICONS.DOCX} />,
  jpg: <SVG src={ICONS.JPG} />,
  png: <SVG src={ICONS.PNG} />,
  gif: <SVG src={ICONS.GIF} />,
  xls: <SVG src={ICONS.XLS} />,
  xlsx: <SVG src={ICONS.XLS} />,
  mp4: <SVG src={ICONS.MP4} />,
  mp3: <SVG src={ICONS.MP3} />,
};


export const getFileIcon = (extension:string | undefined) => {
  if(!extension) return (<SVG src={ICONS.FILE} />);
  if (fileIcons.hasOwnProperty(extension)) {
    return fileIcons[extension];
  }

  return (<SVG src={ICONS.FILE} />);
};
