import { useState } from "react";
import ScrapingService from "../../../domain/service/scraping/scraping";
import { ScrapingDTO } from "../../../domain/models/Scraping";
import { GetParams } from "./types";

export function useGetScraping() {
  const [data, setData] = useState<ScrapingDTO | null>(null);

  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const service = new ScrapingService();

  const controller = new AbortController();

  const get = async (getParams: GetParams) => {
    const { url } = getParams;

    try {
      setIsLoading(true);

      const response: ScrapingDTO = await service.getScraping({
        signal: controller.signal,
        url,
      });

      setData(response);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { get, error, data, isLoading };
}
