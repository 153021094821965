import { Container } from "./Styled";
import { ButtonProps } from "./types";
import Text from "../Text/Text";
import { FontSize, FontWeight } from "../../utils/Fonts";

export function Button(buttonProps: ButtonProps) {
  const {
    loading,
    label,
    backgroundColor,
    disabled,
    fontColor,
    hoveredFontColor,
    hoveredBackgroundColor,
    onClick,
  } = buttonProps;

  return (
    <Container
      type="primary"
      htmlType="submit"
      onClick={() => {
        if (disabled) return;

        if (!onClick) return;

        onClick();
      }}
      disabled={disabled}
      loading={loading}
      $disabled={disabled}
      $backgroundColor={backgroundColor}
      $hoveredBackgroundColor={hoveredBackgroundColor}
      $hoveredFontColor={hoveredFontColor}
    >
      <Text
        value={label}
        fontSize={FontSize.TEXT_SM}
        fontWeight={FontWeight.FONT_NORMAL}
        fontColor={fontColor}
      />
    </Container>
  );
}
