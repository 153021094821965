export interface ScrapingProps {
  favicon?: string;
  image?: string;
  title?: string;
  description?: string;
  url: string;
  backgroundColor: string;
  fontColor: string;
}

export enum MODE {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}
