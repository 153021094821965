import Text from "../Text/Text";
import { MessageProps } from "./types";
import GroupMultimedia from "../GroupMultimedia/GroupMultimedia";
import { Attachment, AttachmentType } from "../../hooks/socket/types";
import { Direction } from "../../utils/Direction";
import { FontSize } from "../../utils/Fonts";
import { calculateContrastYIQ } from "../../utils/Others";
import { useEffect, useState } from "react";
import {
  Container,
  ImageWrapper,
  MessageContent,
  MessageTimeWrapper,
  MessageWrapper,
  MessageWrapperContent,
  TextWrapper,
  VideoWrapper,
} from "./styled";
import AttachmentMessage from "./attachment/AttachmentMessage";
import { getTime } from "../../utils/time";
import { CHAT_STEPS } from "../Chat/types";
import MessageTime from "./MessageTime/MessageTime";
import Scraping from "../Scraping/Scraping";

const COLOR_BLACK = "#222";

const brRegex = /<br\/?>/;

const BKD_COLOR_WHITE = "#f2f2f2";

const BKD_COLOR_DEFAULT = "#0057ff";

const DEFAULT_WRITING_MACHINE_TEXT = "";

export default function Message(messageProps: MessageProps) {
  const {
    text,
    index,
    createdAt,
    userType,
    attachments,
    messageColor,
    groupMultimedia,
    textColor,
    writingMachine,
    nextIsDifferentUser,
    allMessages,
    conversationState,
    handleSelectedAttachment,
    handleAttachments,
  } = messageProps;

  const [writingMachineText, setWritingMachineText] = useState(
    DEFAULT_WRITING_MACHINE_TEXT
  );

  const [urlScraping, setUrlScraping] = useState<string | null>(null);

  function replaceUrlsWithLinks(text: string) {
    // Expresión regular para encontrar URLs en el texto
    const urlRegex =
      /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?([\/?].*)?$/i;

    // Dividir el texto en palabras
    const words = text.split(" ");

    // Reemplazar cada palabra que sea una URL con el formato de enlace HTML
    const replacedWords = words.map((word) => {
      // Eliminar la etiqueta <br/> de la palabra, si está presente
      word = word.replace(brRegex, "");

      if (urlRegex.test(word)) {
        if (!urlScraping) {
          setUrlScraping(word);
        }

        const brTag = brRegex.test(word) ? "<br/>" : "";

        return `
      <a href="${word}" target="_blank" style="color:${fontColor}">
        ${word}
      </a>${brTag}
    `;
      }
      return word;
    });

    // Unir las palabras de nuevo en un texto
    return replacedWords.join(" ");
  }
  function handleScraping(text: string) {
    // Expresión regular para encontrar URLs en el texto
    const urlRegex =
      /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?([\/?].*)?$/i;

    // Dividir el texto en palabras
    const words = text.split(" ");

    // Reemplazar cada palabra que sea una URL con el formato de enlace HTML
    words.map((word) => {
      // Eliminar la etiqueta <br/> de la palabra, si está presente
      word = word.replace(brRegex, "");

      if (urlRegex.test(word) && !urlScraping) {
        setUrlScraping(word);
      }
    });
  }

  useEffect(() => {
    if(!text) return;
    handleScraping(text);
  }, [text])
  

  const handleOpenImage = (attachment: Attachment) => {
    if (!attachments) return;
    handleAttachments([attachment]);
    handleSelectedAttachment(attachment);
  };

  const typingSpeed = 50;

  useEffect(() => {
    if (!writingMachine || !text) return;

    let currentCharacter = 0;

    const interval = setInterval(() => {
      if (currentCharacter < text.length) {
        setWritingMachineText((prevText) => prevText + text[currentCharacter]);
        currentCharacter++;
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fontColor =
    userType === Direction.BOT ? COLOR_BLACK : calculateContrastYIQ(textColor);

  const backgroundColor =
    userType === Direction.BOT
      ? BKD_COLOR_WHITE
      : messageColor ?? BKD_COLOR_DEFAULT;

  const attachmentImages =
    attachments?.filter(
      (attachment) => attachment.type === AttachmentType.IMAGE
    ) ?? []; // count images

  const formattedTextToHtml = () => {
    const breakLines = text?.replaceAll("\n", "<br/>") || "";

    const links = replaceUrlsWithLinks(breakLines);

    return { __html: replaceUrlsWithLinks(links) };
  };

  const validateHasAttachments = () => {
    if (!attachments) return false;

    const emptyAttachments = attachments.filter(
      (attachment) => attachment.type !== "" && attachment.payload.url !== ""
    );

    if (emptyAttachments.length === 0) return false;

    return true;
  };
  const hasScraping = !validateHasAttachments() && urlScraping;

  return (
    <Container direction={userType} $nextIsDifferentUser={nextIsDifferentUser}>
      {!!createdAt && conversationState === CHAT_STEPS.ENABLE_COMMUNICATION && (
        <MessageTime
          createdAt={createdAt}
          index={index}
          allMessages={allMessages}
        />
      )}

      <MessageWrapper direction={userType}>
        <MessageWrapperContent backgroundColor={backgroundColor}>
          {/* SCRAPING */}
          {hasScraping && (
            <Scraping
              url={urlScraping}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
            />
          )}
          {/* MENOS DE 5 ATTACHMENTS */}
          {attachments && attachments.length > 0 && (
            <>
              {attachments.map((attachment) => (
                <>
                  {attachment?.type === AttachmentType.IMAGE &&
                    attachmentImages?.length < 5 && (
                      <ImageWrapper
                        key={attachment?.payload.url}
                        onClick={() => handleOpenImage(attachment)}
                        src={attachment.payload.url}
                        hasText={!!text}
                      />
                    )}

                  {attachment?.type === AttachmentType.VIDEO && (
                    <VideoWrapper
                      key={attachment?.payload.url}
                      src={attachment.payload.url}
                      hasText={!!text}
                      controls
                    >
                      <source src={attachment.payload.url} type="video/mp4" />
                    </VideoWrapper>
                  )}
                  {attachment?.type === AttachmentType.FILE && (
                    <AttachmentMessage
                      key={attachment?.payload.url}
                      attachment={attachment}
                      backgroundColor={backgroundColor}
                    />
                  )}
                </>
              ))}
            </>
          )}

          {/* MAS DE 5 ATTACHMENTS */}
          {attachmentImages?.length > 4 && (
            <GroupMultimedia
              handleAttachments={handleAttachments}
              handleSelectedAttachment={handleSelectedAttachment}
              groupMultimedia={attachmentImages}
            />
          )}

          {/* MESSAGE */}
          {text && (
            <TextWrapper>
              <MessageContent $fontColor={fontColor} $fontSize={"0.875rem"}>
                <Text value={text} fontColor={fontColor} />
              </MessageContent>
            </TextWrapper>
          )}

          {/* TIME */}
          {createdAt && (
            <MessageTimeWrapper>
              <Text
                value={getTime(createdAt)}
                fontSize={FontSize.TEXT_XXS}
                fontColor={fontColor}
              />
            </MessageTimeWrapper>
          )}
        </MessageWrapperContent>
      </MessageWrapper>
    </Container>
  );
}
