export enum TEXT_SIZE {
    XXS = "text-xxs" ,
    XS = "text-xs" /* 12px */,
    SM = "text-sm" /* 14px */,
    BASE = "text-base" /* 16px */,
    LG = "text-lg" /* 18px */,
    XL = "text-xl" /* 18px */,
    "2XL" = "text-2xl" /* 20px */,
    "3XL" = "text-3xl" /* 24px */,
    "4XL" = "text-4xl" /* 30px */,
    "5XL" = "text-5xl" /* 36px */,
    "6XL" = "text-6xl" /* 48px */,
    "7XL" = "text-7xl" /* 60px */,
    "8XL" = "text-8xl" /* 72px */,
    "9XL" = "text-9xl" /* 96px */,
  }
  
  export enum FONT_WEIGHT {
    THIN = "font-thin" /* 100 */,
    EXTRALIGHT = "font-extralight" /* 200 */,
    LIGHT = "font-light" /* 300 */,
    NORMAL = "font-normal" /* 400 */,
    MEDIUM = "font-medium" /* 500 */,
    SEMIBOLD = "font-semibold" /* 600 */,
    BOLD = "font-bold" /* 700 */,
    EXTRABOLD = "font-extrabold" /* 800 */,
    BLACK = "font-black" /* 900 */,
  }