import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TextAreaComponent = styled(TextArea)<{ $height?: string }>`
  line-height: 1.25rem;
  color: #222;
  background-color: #fff;
  font-family: "DM Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 1rem !important;
  resize: none;
  border: none;
  outline: none;
  border-radius: 4px;

  &:active {
    border-color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }
  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;
