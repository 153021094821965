import FileItem from "./FileItem/FileItem";
import { FilePreviewContainer } from "./styled";
import { FilesToSendProps } from "./types";

const FilesToSendPreview = (props: FilesToSendProps) => {

  const { files, onDelete } = props;

  return (
    <FilePreviewContainer>
      {files.map((file, index) => (
        <FileItem
          key={index.toString()}
          file={file}
          onDelete={onDelete}
        />
      ))}
    </FilePreviewContainer>
  );
};

export default FilesToSendPreview;
