import { CSSProperties } from "styled-components";

export type AlignmentStyle = CSSProperties;

export enum Direction {
  BOT = "bot",
  CLIENT = "client",
}

export const getAlignmentStyle = (userType: Direction): AlignmentStyle => {
  switch (userType) {
    case Direction.BOT:
      return {
        marginLeft: 0,
        marginRight: "auto",
        textAlign: "left",
      };
    case Direction.CLIENT:
      return {
        marginLeft: "auto",
        marginRight: 0,
        textAlign: "left",
      };
    default:
      return {};
  }
};
