import styled from "styled-components";
import { useEffect, useState } from "react";
import Chat from "./Chat/Chat";
import BubbleButton from "./BubbleButton/BubbleButton";
import { BubbleChatProps } from "./types";
import useGetConfiguration from "../hooks/configuration/useGetConfiguration";
import { Configuration } from "../../domain/adapters/configuration";
import ConfigurationProvider from "../context/configuration/ConfigurationProvider";
import { useGetScraping } from "../hooks/scraping/useGetScraping";

const Container = styled.div`
  z-index: 9999999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1) 0s;
  transform-origin: center center;
`;

const COUNT_DEFAULT_UNREAD_MESSAGES = 0;
export default function BubbleChat(bubbleChatProps: BubbleChatProps) {
  const { connectionId, right, bottom } = bubbleChatProps;

  if (!connectionId) return;

  const [isOpenChat, setIsOpenChat] = useState(false);

  const [configuration, setConfiguration] = useState<Configuration | null>(
    null
  );

  const [unreadMessages, setUnreadMessages] = useState(
    COUNT_DEFAULT_UNREAD_MESSAGES
  );

  const configurationHook = useGetConfiguration({ connectionId });

  const handleNewMessageUnread = () => {
    setUnreadMessages((oldValue) => oldValue + 1);
  };

  const handleResetMessagesUnread = () => {
    setUnreadMessages((_oldValue) => COUNT_DEFAULT_UNREAD_MESSAGES);
  };

  const handleShowChat = () => {
    if (configurationHook.isLoading) return;

    setIsOpenChat((oldValue) => !oldValue);
  };

  useEffect(() => {
    configurationHook.get();
  }, []);

  useEffect(() => {
    if (configurationHook.data) {
      setConfiguration(configurationHook.data);
    }
  }, [configurationHook.data]);

  useEffect(() => {
    if (configurationHook.error) {
      console.warn("WebChat Omuni: Not exist configuration");
    }
  }, [configurationHook.error]);

  return (
    <Container>
      {!configurationHook.isLoading && configurationHook.data && (
        <ConfigurationProvider configuration={configurationHook.data}>
          <Chat
            right={right}
            bottom={bottom}
            isOpen={isOpenChat}
            connectionId={connectionId}
            handleNewMessageUnread={handleNewMessageUnread}
            handleResetMessagesUnread={handleResetMessagesUnread}
          />
        </ConfigurationProvider>
      )}

      {!configurationHook.isLoading && configurationHook.data && (
        <BubbleButton
          right={right}
          bottom={bottom}
          hasConfiguration={!!configurationHook.data}
          configuration={configuration}
          unreadMessages={unreadMessages}
          isActive={isOpenChat}
          handleClick={handleShowChat}
        />
      )}
    </Container>
  );
}
