import { MessageProps } from "./types";
import { Direction } from "../../utils/Direction";
import { calculateContrastYIQ, lightenColor } from "../../utils/Others";
import {
  Container,
  MessageButtonsWrapper,
} from "./styled";
import { Button } from "../Button/Button";

export default function MessageButtons(messageProps: MessageProps) {
  const {
   showButtons,
   handleReplyWithButton,
   configuration,
    buttons
  } = messageProps;

  const sendButtonResponse = (option: string) => {

    const message = {
      text: option,
      userType: Direction.CLIENT,
      writingMachine: false,
      createdAt: Date.now(),
    };

    handleReplyWithButton(message);
  };

  return (
    <Container>
      
      {showButtons && buttons && (
        <MessageButtonsWrapper>
          {buttons.map((button) => {
            return (
              <Button
                label={button.text}
                backgroundColor={lightenColor(configuration?.primaryColor || '#fff', 0.8)}
                hoveredBackgroundColor={configuration?.primaryColor}
                fontColor={
                  configuration?.primaryColor
                }
                hoveredFontColor={calculateContrastYIQ(
                  configuration?.primaryColor
                )}
                onClick={() => sendButtonResponse(button.text)}
              />
            );
          })}
        </MessageButtonsWrapper>
      )}
    </Container>
  );
}
