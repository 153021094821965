import styled from "styled-components";

export const CommentTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CommentTime = styled.div`
  margin: 10px 0;
  text-align: center;
  width: fit-content;
  letter-spacing: 0.4px;
  background-color: #f2f2f2;
  padding: 6px;
  border-radius: 10px;
`;
