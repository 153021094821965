import { MessageButton } from "../../components/Chat/types";

export interface OmuniMessage {
  clientId: string;
  message?: string;
  attachments: Attachment[];
  timestamp: number;
  messageId: string;
  buttons?: MessageButton[];
}

export interface Attachment {
  type: AttachmentType;
  payload: Payload;
}
export interface Payload {
  url: string;
}

export enum AttachmentType {
  DEFAULT = "",
  IMAGE = "image",
  FILE = "file",
  VIDEO = "video",
}
