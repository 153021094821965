import ConfigurationDTO from "../../models/Configuration";
import Repository from "../../repository/repository";
import { PostUserProps } from "./types";

const URL_BASE = process.env.REACT_APP_API_URL;

export default class UserService {
  private repository = new Repository();
  private baseURL = ''

  constructor() {
    this.baseURL = `${URL_BASE}/api/v1/channels/webchat`; 
  }

  async PostUser(postUserProps: PostUserProps): Promise<any> {

    const url = `${this.baseURL}/user`;
    const response = await this.repository.post<ConfigurationDTO>({
      url,
      body: postUserProps
    }

    );

    return response;
  }
}
