import { ConfigurationContext } from "./configurationContext";
import { ContextProviderProps } from "./types";

const ConfigurationProvider = (props: ContextProviderProps) => {
    
    const { children, configuration } = props;
  
    return <ConfigurationContext.Provider value={configuration}>{children}</ConfigurationContext.Provider>;
  };

  export default ConfigurationProvider