import styled, { keyframes } from "styled-components";
import { Direction, getAlignmentStyle } from "../../utils/Direction";

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px); /* Ajusta la distancia del desvanecimiento */
  }
`;

export const Container = styled.div`
  display: flex;
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  ${(props) => {
    const { marginLeft, marginRight, textAlign } = getAlignmentStyle(
      Direction.CLIENT
    );
    return `
      margin-left: ${marginLeft};
      margin-right: ${marginRight};
      text-align: ${textAlign}; 
    `;
  }}
  gap: 1rem;
  animation: ${fadeOut} 0.5s ease; /* Duración y tipo de animación */

  > img {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

  export const MessageWrapper = styled.div<{
    backgroundColor: string;
  }>`
    background-color: ${(props) => props.backgroundColor};
    padding: 0.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    border-radius: 0.5rem;
  `;
  
  export const MessageButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    justify-content: flex-end
  `;

  export const MessageButton = styled.div`
    display: flex;
    width: 100%
  `;
  
  export const  FileWrapper = styled.a`
    display: flex;
    width: auto;
    height: fit-content;
    cursor: pointer;
  `;
  
  export const  ImageWrapper = styled.img`
    display: flex;
    width: 100%;
    height: 15rem;
    object-fit: contain;
    cursor: pointer;
  `;
  
  export const  VideoWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 15rem;
    cursor: pointer;
    position: relative;
  `;
  export const  VideoContent = styled.video`
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  `;
  
  export const  PlayButton = styled.button`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    outline: none;
  `;
  
  export const  PlayIcon = styled.span`
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #e9edef;
    border-radius: 4px;
  `;