import { PaperClipOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { UploadFileProps } from "./types";
import { FileUploaded } from "../../../domain/models/FileUploaded";
import { useContext, useEffect, useState } from "react";
import { ConfigurationContext } from "../../context/configuration/configurationContext";
import useGetConnection from "../../hooks/connection/useGetConnection";
import styled from "styled-components";


const FileIcon = styled.div`
  & svg {
    width: 20px !important;
    height: 20px !important;
  }
`
const UploadFile = (uploadFileProps: UploadFileProps) => {
  const { colorIcon, fileList, setFileList } = uploadFileProps;

  const configuration = useContext(ConfigurationContext);

  const [authorization, setAuthorization] = useState<string>("");

  const useConnection = useGetConnection();

  useEffect(() => {
    if (!configuration?.connectionId) return;

    useConnection.get({
      connectionId: configuration?.connectionId,
    });
  }, [configuration?.connectionId]);

  useEffect(() => {
    if (!useConnection.data) return;

    if (useConnection.data.webhooks.length === 0) return;

    setAuthorization(
      useConnection.data.webhooks[0].params.headers.Authorization
    );
  }, [useConnection.data]);

  const addNewFileUploaded = (file: FileUploaded) => {
    setFileList([...fileList, file]);
  };

  const removeFileUploaded = (uid: string) => {
    setFileList(fileList.filter((f) => f.uid !== uid));
  };

  const props: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_SIC}/v1/uploads`,
    headers: {
      authorization: authorization,
    },
    multiple: true,
    itemRender: (item) => {
      return null;
    },
    onRemove: (file) => {
      removeFileUploaded(file.uid);
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        addNewFileUploaded({
          name: info.file.name,
          type: info.file?.type?.split("/")[0],
          extension: info.file?.type?.split("/")[1],
          uid: info.file.uid,
          attachment: info.file.response?.files[0],
        });
      }
    },
  };

  return (
    <Upload {...props} disabled={!authorization}>
      <FileIcon >
        <PaperClipOutlined
          style={{ color: authorization ? colorIcon : "lightgray" }}
        />
      </FileIcon>
    </Upload>
  );
};

export default UploadFile;
