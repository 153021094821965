import { ScrapingDTO } from "../../models/Scraping";
import Repository from "../../repository/repository";
import { GetScrapingProps } from "./types";

const URL_BASE = process.env.REACT_APP_API_SIC;

export default class ScrapingService {
  private repository = new Repository();
  private baseURL = "";

  constructor() {
    this.baseURL = `${URL_BASE}/v1/scraping`;
  }

  async getScraping(getScrapingProps: GetScrapingProps): Promise<ScrapingDTO> {
    const { url, signal } = getScrapingProps;

    const endpoint = `${this.baseURL}`;

    const response = await this.repository.get<ScrapingDTO>(
      endpoint,
      undefined,
      undefined,
      signal,
      {
        url: url,
      }
    );

    return response;
  }
}
