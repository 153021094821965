import ReactDOM from "react-dom";
import App from "./components/App";
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import root from "react-shadow/styled-components";
import './styles.css'

const rootElement = document.getElementById("OmuniWebChat");

const connectionId = rootElement?.getAttribute("data-connection-id") ?? null;
const bottom = rootElement?.getAttribute("data-bottom") ?? null;
const right = rootElement?.getAttribute("data-right") ?? null;


ReactDOM.render(
  <root.div>
    <ThemeProvider theme={theme}>
      <App connectionId={connectionId} right={right} bottom={bottom} />
    </ThemeProvider>
  </root.div>,
  rootElement
);
