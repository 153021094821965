export enum HEADER_TYPES {
    APPLICATION_JSON = 'application/json',
    MULTIPART_FORMDATA = 'multipart/form-data',
}

export interface PostParams {
    url: string,
    body: any,
    contentType?: HEADER_TYPES,
    authorization?: string,
    signal?: AbortSignal
  }