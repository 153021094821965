import { getFileIcon } from "../../../utils/files";
import { FileIcon, FileInfo, FileName, FilePreview } from "./styled";
import { AttachmentMessageProps } from "./types";
import { FontSize } from "../../../utils/Fonts";
import { calculateContrastYIQ, darkenColor } from "../../../utils/Others";
import { NewText } from "../../NewText/Text";
import { TEXT_SIZE } from "../../NewText/Font";

export default function AttachmentMessage(props: AttachmentMessageProps) {
  const { attachment, backgroundColor } = props;

  const darkerBackgroundColor = darkenColor(backgroundColor);

  const fontColor = calculateContrastYIQ(darkerBackgroundColor);

  const getFileName = (url: string) => {
    const filenameIndex = url.indexOf("filename=");
    if (filenameIndex !== -1) {
      const filenameAndParams = url.substring(filenameIndex + 9); // Sumamos 9 para empezar desde el final de "filename="
      return filenameAndParams;
    }
    return "";
  };

  const fileName = getFileName(attachment.payload.url);

  return (
    <FilePreview 
      onClick={() => window.open(attachment.payload.url)}
      $backgroundColor={darkerBackgroundColor}
    >
      <FileIcon>
        {getFileIcon(
          attachment.payload.url.substring(attachment.payload.url.length - 3)
        )}
      </FileIcon>
      <FileInfo>
        <FileName $fontColor={fontColor}>
          <NewText 
            value={fileName}
            fontSize={TEXT_SIZE.XS}
            fontColor={fontColor}
            lineCount={1}
          />
        </FileName>
      </FileInfo>
    </FilePreview>
  );
}
