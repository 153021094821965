import styled from "styled-components";
import TextInput from "../TextInput/TextInput";
import { useState } from "react";
import { Button } from "../Button/Button";
import Text from "../Text/Text";
import { RegisterFormProps } from "./types";
import { FontSize } from "../../utils/Fonts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const COLOR_BLACK = "#222";

const BKD_COLOR_BLACK = "#233348";

const BKD_COLOR_DEFAULT = "#b1b1b1";

const REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function RegisterForm(registerFormProps: RegisterFormProps) {
  const { handleRegister, backgroundButton } = registerFormProps;
  const handleRegisterUser = () => {
    handleRegister(nameComplete, email);
  };

  const [nameComplete, setNameComplete] = useState("");

  const [email, setEmail] = useState("");

  const validateEmail = (email: string) => {
    return REGEX.test(String(email).toLowerCase());
  };

  return (
    <Container>
      <Text
        value="Para comenzar una conversación, complete los siguientes datos:"
        fontColor={COLOR_BLACK}
        fontSize={FontSize.TEXT_SM}
      />
      <TextInput
        label="Nombre completo"
        placeholder="Ej. Juan Pérez"
        handleChange={setNameComplete}
      />
      <TextInput
        label="Email"
        placeholder="Ej. juan@example.com"
        handleChange={setEmail}
      />
      <Button
        label="Iniciar"
        onClick={handleRegisterUser}
        disabled={!validateEmail(email) || !nameComplete}
        backgroundColor={
          !validateEmail(email) || !nameComplete
            ? BKD_COLOR_DEFAULT
            : backgroundButton ?? BKD_COLOR_BLACK
        }
        width="5rem"
      />
    </Container>
  );
}
