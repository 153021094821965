import { TextAreaComponent } from "./Styled";
import { TextAreaProps } from "./types";

export default function TextArea(props: TextAreaProps) {
  const { text, placeholder, disabled, onChange, onKeyDown, onBlur } = props;
  return (
    <TextAreaComponent
      value={text}
      placeholder={placeholder}
      autoSize={{ minRows: 1, maxRows: 4 }}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
}
