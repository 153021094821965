import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GalleryProps } from "./types";
import { Attachment, AttachmentType } from "../../hooks/socket/types";
import ArrowButton from "../ArrowButton/ArrowButton";
import { ORIENTATION } from "../ArrowButton/types";

const Container = styled.div<{ isOpen: boolean }>`
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  z-index: ${(props) => (props.isOpen ? "99999999" : "0")};
  position: fixed;
  background-color: rgba(34, 34, 34, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
  height: fit-content;
`;

const VideoWrapper = styled.video<{
  loaded: boolean;
  width?: number;
  height?: number;
}>`
  display: block;
  position: relative;
  margin: auto;
  opacity: ${(props) => (props.loaded ? "1" : "0")};
  visibility: ${(props) => (props.loaded ? "visible" : "hidden")};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
`;

const ImageWrapper = styled.img<{
  loaded: boolean;
  width?: number;
  height?: number;
}>`
  display: block;
  position: relative;
  margin: auto;
  opacity: ${(props) => (props.loaded ? "1" : "0")};
  visibility: ${(props) => (props.loaded ? "visible" : "hidden")};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  width: auto;
  height: 70vh;
`;

const ButtonLeft = styled.div`
  position: absolute;
  top: 0;
  z-index: 9999999;
  bottom: 0;
  left: 2rem;
  margin: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  > svg {
    transform: rotate(180deg);
  }
`;

const ButtonRight = styled.div`
  z-index: 9999999;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2rem;
  margin: auto;
`;

const CONTAINER_SIZE = {
  width: 0,
  height: 0,
};

export default function Gallery(galleryProps: GalleryProps) {
  const { isOpen, handleCloseGallery, attachments, selected } = galleryProps;

  const [widthVideo, setWidthVideo] = useState<number | undefined>(undefined);

  const [heightVideo, setHeightVideo] = useState<number | undefined>(undefined);

  const imageRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);


  const [selectedAttachment, setSelectedAttachment] =
    useState<Attachment | null>(null);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [containerSize, setContainerSize] = useState<{
    width: number;
    height: number;
  }>(CONTAINER_SIZE);

  useEffect(() => {
    if (selected) {
      setLoaded(false);
      setSelectedAttachment(selected);
    }
  }, [selected]);

  useEffect(() => {
    const calculateAspectRatioVideo = async () => {
      if (
        selectedAttachment &&
        containerSize.width > 0 &&
        containerSize.height > 0
      ) {
        const video = document.createElement("video");

        return new Promise<void>((resolve) => {
          video.addEventListener("loadedmetadata", () => {
            const { videoWidth, videoHeight } = video;
            const containerAspectRatio =
              containerSize.width / containerSize.height;
            const videoAspectRatio = videoWidth / videoHeight;

            if (containerAspectRatio > videoAspectRatio) {
              const newWidth = containerSize.height * videoAspectRatio;
              setWidthVideo(newWidth);
              setHeightVideo(containerSize.height);
            } else {
              const newHeight = containerSize.width / videoAspectRatio;
              setWidthVideo(containerSize.width);
              setHeightVideo(newHeight);
            }

            resolve();
          });

          video.addEventListener("error", () => {
            resolve();
          });

          video.src = selectedAttachment.payload.url;
          video.load();
        });
      }
    };

    calculateAspectRatioVideo();
  }, [selectedAttachment, containerSize]);

  const handleBackMultimedia = (e: any) => {
    e.stopPropagation();
    if (attachments && selectedAttachment) {
      setLoaded(false);

      const currentIndex = attachments.findIndex(
        (attachment) => attachment === selectedAttachment
      );

      if (currentIndex > 0) {
        setSelectedAttachment(attachments[currentIndex - 1]);
        return;
      }

      setSelectedAttachment(attachments[attachments.length - 1]);
    }
  };

  const handleNextMultimedia = (event: any) => {
    event.stopPropagation();
    if (attachments && selectedAttachment) {
      setLoaded(false);

      const currentIndex = attachments.findIndex(
        (attachment) => attachment === selectedAttachment
      );

      if (currentIndex < attachments.length - 1) {
        setSelectedAttachment(attachments[currentIndex + 1]);
        return;
      }

      setSelectedAttachment(attachments[0]);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target instanceof Element && event.target.closest(".ContentWrapper")) {
      return;
    }
    
    handleCloseGallery();
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          handleCloseGallery();
        }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
        document.removeEventListener('keydown', handleKeyPress);
    };
}, []);

  return (
    <Container isOpen={isOpen} onClick={handleClose}>
      
      <ContentWrapper ref={containerRef}>
      {attachments && attachments.length > 1 && (
        <ButtonLeft  onClick={handleBackMultimedia}>
          <ArrowButton orientation={ORIENTATION.LEFT}/>
        </ButtonLeft>
      )}
        {selectedAttachment?.type === AttachmentType.IMAGE && (
          <ImageWrapper
            onLoad={() => setLoaded(true)}
            ref={imageRef}
            loaded={loaded}
            src={selectedAttachment?.payload.url}
          />
        )}
        {selectedAttachment?.type === AttachmentType.VIDEO && (
          <VideoWrapper
            ref={videoRef}
            loaded
            controls
            width={widthVideo}
            height={heightVideo}
            src={selectedAttachment?.payload.url}
          />
        )}
        {attachments && attachments.length > 1 &&(
        <ButtonRight onClick={handleNextMultimedia}>
          <ArrowButton orientation={ORIENTATION.RIGHT}/>
        </ButtonRight>
      )}
      </ContentWrapper>
    </Container>
  );
}
