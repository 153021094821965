import styled from "styled-components";
import { MODE } from "./types";

export const Container = styled.div<{ backgroundColor: string; mode: MODE }>`
  ${(props) => {
    if (props.mode === MODE.VERTICAL) {
      return `         
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        `;
    }
    if (props.mode === MODE.HORIZONTAL) {
      return `
          display:grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          gap: 0.25rem;
        `;
    }
  }}
  width: 290px;
  height: auto;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  overflow: hidden;
`;

export const LinkImageWrapper = styled.img`
  display: flex;
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
  padding-bottom: 0;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
`;
export const LinkFaviconWrapper = styled.img`
  display: flex;
  width: 5rem;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  grid-column: span 1 / span 1;
  cursor: pointer;
  padding-bottom: 0;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const DescriptionWrapper = styled.div<{ mode: MODE }>`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;

  ${(props) => {
    if (props.mode === MODE.VERTICAL) {
      return `         
      grid-column: span 1 / span 1;
        `;
    }
    if (props.mode === MODE.HORIZONTAL) {
      return `
      grid-column: span 2 / span 2;
        `;
    }
  }}
  box-sizing: border-box;
`;
