import styled from "styled-components";
import { ArrowButtonProps, ORIENTATION } from "./types";

const Container = styled.svg``;

export default function ArrowButton(arrowButtonProps: ArrowButtonProps) {
  const { orientation } = arrowButtonProps;
  return orientation === ORIENTATION.LEFT ? (
    <Container width="48" height="48" fill="none">
      <g filter="url(#a)">
        <g clip-path="url(#b)">
          <rect
            x="4"
            y="42"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-90 4 42)"
            fill="#FFFBFE"
          />
          <rect
            x="4"
            y="42"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-90 4 42)"
            fill="#757575"
          />
        </g>
      </g>
      <path
        d="m20.096 27.416 5.916-5.916-5.916-5.929 1.821-1.82 7.75 7.75-7.75 7.75-1.821-1.835Z"
        fill="#fff"
      />
      <defs>
        <clipPath id="b">
          <rect
            x="4"
            y="42"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-90 4 42)"
            fill="#fff"
          />
        </clipPath>
        <filter
          id="a"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2750_22315"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2750_22315"
            result="shape"
          />
        </filter>
      </defs>
    </Container>
  ) : (
    <Container width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g filter="url(#filter0_d_2750_22315)">
        <g clip-path="url(#clip0_2750_22315)">
          <rect
            x="4"
            y="42"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-90 4 42)"
            fill="#FFFBFE"
          />
          <rect
            x="4"
            y="42"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-90 4 42)"
            fill="#757575"
          />
        </g>
      </g>
      <path
        d="M20.0957 27.4159L26.0115 21.5001L20.0957 15.5713L21.917 13.7501L29.667 21.5001L21.917 29.2501L20.0957 27.4159Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_2750_22315"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2750_22315"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2750_22315"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2750_22315">
          <rect
            x="4"
            y="42"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-90 4 42)"
            fill="white"
          />
        </clipPath>
      </defs>
    </Container>
  );
}
