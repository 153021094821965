import { useState } from "react";
import { UseGetConfigurationProps } from "./types";
import ConfigurationService from "../../../domain/service/configuration/configuration";
import { getExternalLoggedUser, trasformConfiguration } from "../../utils/configuration";

const useGetConfiguration = (
  useGetConfigurationProps: UseGetConfigurationProps
) => {
  const { connectionId } = useGetConfigurationProps;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const configurationService = new ConfigurationService();
  const get = async () => {
    try {
      const configCall = await configurationService.getConfiguration({
        connectionId,
      });

      const user = getExternalLoggedUser();

      let updatedConfig = {...configCall};

      if(user){
        updatedConfig = trasformConfiguration({
          configuration:configCall, 
          externalUser: user
        })
      };

      setData(updatedConfig);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, get };
};

export default useGetConfiguration;
