import styled from "styled-components";
import Icon from "../../utils/Icons";
import { BubbleButtonProps } from "./types";
import { calculateContrastYIQ } from "../../utils/Others";

const Container = styled.div<{ right: string | null; bottom: string | null }>`
  user-select: none;
  z-index: 09999999;
  position: fixed;
  right: ${(props) =>
    props.right && parseInt(props.right) > 7 ? `${props.right}px` : "20px"};
  bottom: ${(props) =>
    props.bottom && parseInt(props.bottom) > 7 ? `${props.bottom}px` : "20px"};
  width: 3rem;
  height: 3rem;
  transition: transform 0.5s ease-in-out; /* Cambiamos la duración y el tipo de transición */
  transform-origin: center center;
  cursor: "pointer";
`;

const WrapperButton = styled.div<{ background?: string }>`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: ${(props) => props.background ?? "#233348"};
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  backface-visibility: hidden;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;

  &:hover {
    transition: all 250ms cubic-bezier(0.33, 0, 0, 1) 0s;
    transform: scale(1.1);
  }
`;

const ButtonChat = styled.div<{ isActive: boolean }>`
  user-select: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 3rem;
  height: 3rem;
  transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
  opacity: 1;
  transform: rotate(0deg) scale(1);
  transform: ${(props) =>
    props.isActive ? "rotate(180deg);" : "rotate(0deg);"};
  opacity: ${(props) => (props.isActive ? "0" : "1")};
  padding: 0.25rem;
  box-sizing: border-box;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const ButtonArrow = styled.div<{ isActive: boolean }>`
  user-select: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
  opacity: 1;
  transform: ${(props) =>
    props.isActive ? "rotate(0deg);" : "rotate(180deg);"};
  opacity: ${(props) => (props.isActive ? "1" : "0")};

  > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Notification = styled.div<{ isActive: boolean }>`
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  transform: ${(props) =>
    props.isActive ? "rotate(0deg);" : "rotate(180deg);"};
  transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
  background-color: red;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  z-index: 9999;
  color: white;
  font-size: 0.75rem;
  line-height: 1rem;
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
`;

export default function BubbleButton(bubbleButtonProps: BubbleButtonProps) {
  const {
    handleClick,
    isActive,
    unreadMessages,
    configuration,
    right,
    bottom,
    hasConfiguration,
  } = bubbleButtonProps;

  const handleClickEvent = () => {
    if (!hasConfiguration) return;
    handleClick();
  };

  const hasIcon =
    configuration &&
    (configuration?.launcherIcon || configuration?.launcherIcon !== "");

  return (
    <Container bottom={bottom} right={right} onClick={handleClickEvent}>
      <Notification isActive={!isActive && unreadMessages > 0}>
        {unreadMessages}
      </Notification>
      <WrapperButton background={configuration?.primaryColor}>
        <ButtonChat isActive={isActive}>
          <img src={hasIcon ? configuration?.launcherIcon : Icon.LOGO} />
        </ButtonChat>
        <ButtonArrow isActive={isActive}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="10"
            viewBox="0 0 17 10"
            fill="none"
          >
            <path
              d="M2.58417 0.095459L8.5 6.01129L14.4288 0.095459L16.25 1.91671L8.5 9.66671L0.75 1.91671L2.58417 0.095459Z"
              fill={calculateContrastYIQ(configuration?.primaryColor)}
            />
          </svg>
        </ButtonArrow>
      </WrapperButton>
    </Container>
  );
}
