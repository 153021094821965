import axios from "axios";
import { HEADER_TYPES, PostParams } from "./types";
import httpClient from "../configuration/axios";

export default class Repository {
  async post<T>(params: PostParams): Promise<T> {
    try {
      const { url, body, contentType, authorization, signal } = params;

      const fullURL = url;

      const headers: any = {};

      headers["Content-Type"] = contentType ?? HEADER_TYPES.APPLICATION_JSON;

      if (authorization) {
        headers["Authorization"] = authorization;
      }

      const response = await axios.post(fullURL, body, {
        headers,
        signal,
      });

      const responseData = response.data;

      return responseData as T;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async get<T>(
    url: string,
    contentType?: HEADER_TYPES,
    authorization?: string,
    signal?: AbortSignal,
    params?: { [key: string]: string }
  ): Promise<T> {
    try {
      const fullURL = url;

      const headers: any = {};

      headers["Content-Type"] = contentType ?? HEADER_TYPES.APPLICATION_JSON;

      if (authorization) {
        headers["Authorization"] = authorization;
      }

      const response = await axios.get(fullURL, {
        headers,
        signal,
        params,
      });

      const responseData = response.data;

      return responseData as T;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
