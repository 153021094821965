import styled from "styled-components";
import { TextInputProps } from "./types";
import { useState } from "react";
import Text from "../Text/Text";
import { FontSize } from "../../utils/Fonts";
import { genPlaceholderStyle } from "antd/es/input/style";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;
  height: 2.5rem;
  border-radius: 0.25rem;
  padding: 0 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  font-family: "DM Sans", sans-serif !important;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: "DM Sans", sans-serif !important;
  }
`;

const ENTER = "Enter";

const DEFAULT = "";

const COLOR_BLACK = "#333";

export default function TextInput(textInputProps: TextInputProps) {
  const { label, handleEnter, handleChange, placeholder } = textInputProps;

  const [value, setValue] = useState("");

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setValue(newValue);

    if (!handleChange) return;

    handleChange(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER) {
      if (!handleEnter) return;

      setValue(DEFAULT);
      handleEnter(value);
    }
  };

  return (
    <Container>
      <Text value={label} fontSize={FontSize.TEXT_SM} fontColor={COLOR_BLACK} />
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleChangeInput}
        onKeyDown={handleKeyDown}
      />
    </Container>
  );
}
