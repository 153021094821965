/**
 * Calculates the contrast of a given hex color to determine whether to display white or black text.
 *
 * @param {string} hexcolor - The hex color code to calculate the contrast for.
 * @param {number} transparency - Optional transparency value for the resulting color.
 * @return {string} Either "rgba(0,0,0, transparency)" for dark backgrounds or "rgba(255,255,255, transparency)" for light backgrounds.
 */
export const calculateContrastYIQ = (
  hexcolor?: string,
  transparency?: number
) => {
  if (!hexcolor) {
    return "white";
  }

  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128
    ? `rgba(0,0,0, ${transparency ?? 1})`
    : `rgba(255,255,255, ${transparency ?? 1})`;
};

/**
 * Shades the input color by the specified percentage.
 *
 * @param {string} color - the color to shade
 * @param {number} percent - the percentage by which to shade the color
 * @return {string} the shaded color in hexadecimal format
 */
export function shadeColor(color: string, percent: number): string {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.round((R * (100 + percent)) / 100);
  G = Math.round((G * (100 + percent)) / 100);
  B = Math.round((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).padStart(2, '0');
  const GG = G.toString(16).padStart(2, '0');
  const BB = B.toString(16).padStart(2, '0');

  return `#${RR}${GG}${BB}`;
}

export function lightenColor(hex: string, opacity: number): string {
  // Convertir el color hexadecimal a RGB
  let r: number = parseInt(hex.substring(1, 3), 16);
  let g: number = parseInt(hex.substring(3, 5), 16);
  let b: number = parseInt(hex.substring(5, 7), 16);

  // Ajustar la opacidad
  opacity = Math.min(Math.max(opacity, 0), 1);

  // Calcular el nuevo color con la opacidad ajustada
  let newR: number = Math.round(r + (255 - r) * opacity);
  let newG: number = Math.round(g + (255 - g) * opacity);
  let newB: number = Math.round(b + (255 - b) * opacity);

  // Convertir el nuevo color RGB a hexadecimal
  let newHex: string = "#" + ((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1);

  return newHex;
}

/**
 * Darkens the input color by reducing its RGB components by 30 units.
 *
 * @param {string} color - the color in hexadecimal format
 * @return {string} the darkened color in hexadecimal format
 */
export function darkenColor(color: string): string {
  // Parse the color into RGB values
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  // Darken the color by reducing each RGB component
  const darkenedR = Math.max(0, r - 10);
  const darkenedG = Math.max(0, g - 10);
  const darkenedB = Math.max(0, b - 10);

  // Convert the darkened RGB values back to a hex color
  const darkenedColor = `#${darkenedR.toString(16).padStart(2, '0')}${darkenedG.toString(16).padStart(2, '0')}${darkenedB.toString(16).padStart(2, '0')}`;

  return darkenedColor;
}