import styled from "styled-components";

const DeleteButton = styled.button`
  display: none;
  position: absolute;
  top: -10px;
  right: -6px;
  border: none;
  cursor: pointer;
  background-color: #f6f6f6;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 24px;
  line-height: 24px;
  & svg {
    width: 12px;
  }
`;

const Container = styled.div`
  position: relative;

  &:hover {
    ${DeleteButton} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const ImagePreviewWrapper = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100px;
  gap: 0.2rem;
  color: #000;
`;

const FileName = styled.div`
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FileIcon = styled.div`
  margin-right: 10px;

  svg {
    width: 30px;
    height: fit-content;
  }
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $ligthgrey_4;
  position: relative;
  cursor: pointer;
  height: 50px;
`;

export {
  Container,
  DeleteButton,
  ImagePreview,
  ImagePreviewWrapper,
  FileInfo,
  FileName,
  FileIcon,
  FilePreview,
};
