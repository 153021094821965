import { FontSize } from "../../../utils/Fonts";
import { displayCommentDate } from "../../../utils/time";
import Text from "../../Text/Text";
import { CommentTimeWrapper } from "../styled";
import { CommentTime } from "./styled";
import { MessageTimeProps } from "./types";

export default function MessageTime(props: MessageTimeProps) {
  const { createdAt, index, allMessages } = props;

  const timeToShow = displayCommentDate(createdAt, index, allMessages);

  if (!timeToShow) return null;

  return (
    <CommentTimeWrapper>
      <CommentTime>
        <Text
          value={timeToShow}
          fontSize={FontSize.TEXT_SM}
          fontColor={"#989898"}
        />
      </CommentTime>
    </CommentTimeWrapper>
  );
}
