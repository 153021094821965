import styled from "styled-components";
import { SendButtonProps } from "./types";

const Button = styled.div<{$isEnabled: boolean}>`
  cursor: ${(props)=> props.$isEnabled ? "pointer" : "not-allowed"};
  height: 1.5rem;
  width: 1.5rem;
`;
const COLOR_DISABLED = "#CCC";

const COLOR_DEFAULT = "rgb(0, 87, 255)";

export default function SendButton(sendButtonProps: SendButtonProps) {
  const { handleClick, isEnabled, arrowColor } = sendButtonProps;

  return (
    <Button $isEnabled={isEnabled} onClick={handleClick}>
      <svg
        width="22"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.68 9.164 3.402 10.21a.417.417 0 0 0-.322.265L.917 16.273a.625.625 0 0 0 .862.785l15-7.5a.625.625 0 0 0 0-1.117l-15-7.5a.625.625 0 0 0-.862.785l2.165 5.798a.417.417 0 0 0 .321.265L9.68 8.835a.167.167 0 0 1 0 .33Z"
          fill={
            isEnabled
              ? arrowColor ?? COLOR_DEFAULT
              : COLOR_DISABLED
          }
        />
      </svg>
    </Button>
  );
}
