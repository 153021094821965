import styled from "styled-components";
import { Input as AntdInput } from "antd";
const { TextArea } = AntdInput;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsWrapper = styled.div<{ isFocused: boolean }>`
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: normal;
  background-color: rgb(255, 255, 255);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-height: 10rem;
  position: relative;
  transition: all 167ms cubic-bezier(0.33, 0, 0, 1) 0s;
  box-shadow: ${(props) =>
    props.isFocused && "rgba(0, 0, 0, 0.1) 0px 0px 100px 0px;"};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTextArea = styled(TextArea)`
  color: #222;
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  resize: none;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: "DM Sans", sans-serif !important;
  font-size: 14px;
`;
export const IconsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const InputWithIcons = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1;

  textarea {
    flex: 1;
  }
`;

export const IconContainer = styled.div`
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin-top: -2px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2px;
`;
