import styled from "styled-components";

export const FileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.2rem;
  color: #000;
`;

export const FileName = styled.div<{$fontColor: string}>`
  display: block;
  max-width: 170px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({$fontColor}) => $fontColor};
`;

export const FileIcon = styled.div`
  margin-right: 10px;

  svg {
    width: 30px;
    height: fit-content;
  }
`;

export const FilePreview = styled.div<{$backgroundColor: string}>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $ligthgrey_4;
  position: relative;
  cursor: pointer;
  height: 50px;
  padding: 6px;
  margin: 6px;
  background-color: ${({$backgroundColor}) => $backgroundColor};
  max-width: 210px;
`;