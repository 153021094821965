import styled from "styled-components";
import {
  FontSize,
  FontWeight,
  getFontSizeAndLineHeight,
  getFontWeight,
} from "../../utils/Fonts";
import Paragraph from "antd/es/typography/Paragraph";

interface TextProps {
  fontWeight?: FontWeight;
  fontSize?: FontSize;
  fontColor?: string;
  value: string;
  truncate?: boolean; // Nueva prop para controlar el truncado condicional
}

const Container = styled(Paragraph)<{
  $fontSize?: FontSize;
  $fontWeight?: FontWeight;
  $fontColor?: string;
  $truncate?: boolean; // Prop para controlar el truncado condicional
}>`
  color: ${(props) => props.$fontColor ?? "#FFF"};
  margin: unset;
  font-family: "DM Sans", sans-serif !important;
  font-weight: ${(props) => getFontWeight(props.$fontWeight)};
  ${(props) => {
    const { fontSize, lineHeight } = getFontSizeAndLineHeight(props.$fontSize);
    let styles = `
      font-size: ${fontSize};
      line-height: ${lineHeight};
      
    `;
    if (props.$truncate) {
      styles += `
        text-overflow: ellipsis;
        max-width: 97%; /* ajusta esto según tu diseño */
        overflow: hidden;
      white-space: nowrap;
      line-clamp: 1;
      `;
    }
    return styles;
  }}
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Strikethrough = styled.del``;

export const Bold = styled.b`
  font-weight: 700;
`;

interface TextPart {
  type: "normal" | "bold" | "link";
  value: string;
}

export default function Text(textProps: TextProps) {
  const { value, fontSize, fontWeight, fontColor, truncate } = textProps;

  const textSize = fontSize ?? FontSize.TEXT_BASE;

  const textWeight = fontWeight ?? FontWeight.FONT_NORMAL;

  function parseFormattedText(text: string): TextPart[] {
    const parts: TextPart[] = [];
    let currentPart = "";
    let i = 0;

    while (i < text.length) {
      // Detectar partes en negrita entre un solo asterisco *
      if (text.startsWith("*", i) && !text.startsWith("**", i)) {
        i++; // Skip '*'
        if (currentPart) {
          parts.push({ type: "normal", value: currentPart });
          currentPart = "";
        }
        let boldPart = "";
        while (i < text.length && text[i] !== "*") {
          boldPart += text[i];
          i++;
        }
        parts.push({ type: "bold", value: boldPart });
        i++; // Skip '*'
      }
      // Detectar enlaces que comienzan con https://
      else if (text.startsWith("https://", i)) {
        if (currentPart) {
          parts.push({ type: "normal", value: currentPart });
          currentPart = "";
        }
        let linkPart = "https://";
        i += 8; // Saltar "https://"
        while (i < text.length) {
          linkPart += text[i];
          i++;
        }
        parts.push({
          type: "link",
          value: linkPart,
        });
      } else {
        currentPart += text[i];
        i++;
      }
    }

    if (currentPart) {
      parts.push({ type: "normal", value: currentPart });
    }

    return parts;
  }

  const parsedText = parseFormattedText(value ?? "");

  function renderText(parsedText: any) {
    return parsedText.map((textPart: any, index: number) => {
      switch (textPart.type) {
        case "normal":
          return textPart.value;
        case "link":
          return (
            <a
              key={index}
              href={textPart.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {textPart.value}
            </a>
          );
        default:
          return null;
      }
    });
  }

  return (
    <Container
      ellipsis
      suppressHydrationWarning
      $fontColor={fontColor}
      $truncate={truncate}
      $fontSize={textSize}
      $fontWeight={textWeight}
    >
      {renderText(parsedText)}
    </Container>
  );
}
