import { useState } from "react";
import UserService from "../../../domain/service/user/user";
import { UsePostUserProps } from "./types";

const usePostUser = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const userService = new UserService();
  const post = async (usePostUserProps: UsePostUserProps) => {
    try {
      const configCall = await userService.PostUser(usePostUserProps);
      setData(configCall);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, post };
};

export default usePostUser;
