import ConfigurationAdapter from "../../adapters/types";
import ConfigurationDTO from "../../models/Configuration";
import Repository from "../../repository/repository";
import { GetConfigurationProps } from "./types";

const URL_BASE = process.env.REACT_APP_API_URL;

export default class ConfigurationService {
  private repository = new Repository();
  private baseURL = '';

  constructor() {
    this.baseURL = `${URL_BASE}/api/v1/channels/webchat`; 
  }

  async getConfiguration(
    getConfigurationProps: GetConfigurationProps
  ): Promise<any> {
    const { connectionId } = getConfigurationProps;

    const urlWithId = `${this.baseURL}/config/${connectionId}`;

    const response = await this.repository.get<ConfigurationDTO>(urlWithId);

    const adapted = ConfigurationAdapter(response);

    return adapted;
  }
}
