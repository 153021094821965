import ConfigurationDTO from "../models/Configuration";
import { Configuration } from "./configuration";

const ConfigurationAdapter = (
  configurationDTO: ConfigurationDTO
): Configuration => {
  const data: Configuration = {
    id: configurationDTO.config._id,
    primaryColor: configurationDTO.config.color,
    secondaryColor: configurationDTO.config.secondColor,
    connectionId: configurationDTO.config.connectionId,
    title: configurationDTO.config.title,
    subtitle: configurationDTO.config.subtitle,
    launcherIcon: configurationDTO.config.launcherIcon,
    titleAvatar: configurationDTO.config.titleAvatar,
    createdAt: configurationDTO.config.createdAt,
    updatedAt: configurationDTO.config.updatedAt,
    initialMessage: configurationDTO.config?.initialMessage ?? '',
  };

  return data;
};

export default ConfigurationAdapter;
