import OmuniIcon from "../assets/images/logo-omuni-iso.webp";
import ArrowDownIcon from "../assets/images/arrow_down.webp";
import sendEnabledIcon from "../assets/images/send-enabled.webp";
import emojiIcon from "../assets/images/emoji.webp";
import fileIcon from "../assets/images/file.webp"; 
import pdfIcon from "../assets/images/pdf.webp"; 
import botV1Icon from "../assets/images/bot-version-1.webp"; 
import botV2Icon from "../assets/images/bot-version-2.webp"; 

const Icon = {
  LOGO: OmuniIcon,
  ARROW_DOWN: ArrowDownIcon,
  SEND_ENABLED: sendEnabledIcon,
  EMOJI: emojiIcon,
  FILE: fileIcon,
  PDF: pdfIcon,
  BOT_V1: botV1Icon,
  BOT_V2: botV2Icon,
};

export default Icon;
