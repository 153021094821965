export const addZeroForDate = (number: number) =>
  number < 10 ? `0${number}` : number;
import { isBefore, subWeeks, format } from "date-fns";
import { MessageModel } from "../components/InputChat/types";
import { es } from "date-fns/locale";
import { useTranslation } from "react-i18next";


export const getTime = (fullDate: number) => {
  const time = new Date(fullDate);
  let displayTime = `${addZeroForDate(time.getHours())}:${addZeroForDate(
    time.getMinutes()
  )}`;
  return displayTime;
};

export const getDate = (fullDate: number) => {
  const date = new Date(fullDate);
  let displayDate = "";
  displayDate = `${addZeroForDate(date.getDate())}/${addZeroForDate(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
  return displayDate;
};

export const isBeforeThanOneWeekAgoFromNow = (date:Date) => {
  return isBefore(date, subWeeks(new Date(), 1));
};

export const displayCommentDate = (createdAt: number, i: number, comments: MessageModel[]) => {
  const { t } = useTranslation(['date']);

    if (i > 0 && getDate(createdAt) === getDate(comments[i - 1].createdAt)) {
    //dont show the date of the message if the last previous one
    //is from the same date
    return null;
  }

  const systemDate = new Date();
  const today = systemDate.getDate();
  const yesterday = systemDate.getDate() - 1;
  let todayComments = comments.filter(
    (comment) => new Date(comment.createdAt).getDate() === today
  );
  let yesterdayComments = comments.filter(
    (comment) => new Date(comment.createdAt).getDate() === yesterday
  );

  if (todayComments.length > 0 && createdAt === todayComments[0].createdAt) {
    return "Hoy";
  } else if (todayComments.find((todayComment) => todayComment.createdAt === createdAt)) {
    return null;
  } else if (yesterdayComments.length > 0 && createdAt === yesterdayComments[0].createdAt) {
    return "Ayer";
  } else if (
    yesterdayComments.find((yesterdayComment) => yesterdayComment.createdAt === createdAt)
  ) {
    return null;
  } else {
    const mydate = new Date(createdAt);
    const formattedDate = format(mydate, "d MMM yyyy", { locale: es }); // Use es locale for Spanish

    const capitalizedDate = formattedDate.replace(
      /(\b\w{1})(\w+)/g,
      (match, p1, p2) => p1.toUpperCase() + p2
    );

    const capitalizedDayName = t(format(mydate, 'iii', { locale: es })).replace(
      /(\b\w{1})(\w+)/g,
      (match, p1, p2) => p1.toUpperCase() + p2
    );

    return `${
      !isBeforeThanOneWeekAgoFromNow(mydate) ? capitalizedDayName : ""
    } ${capitalizedDate}`;
  }
};
