import styled from "styled-components";
import { Direction, getAlignmentStyle } from "../../utils/Direction";

export const Container = styled.div<{
  direction: Direction;
  $nextIsDifferentUser: boolean;
}>`
  border-radius: 0.5rem;
  width: 100%;
  max-width: 320px;
  margin-bottom: ${(props) => (props.$nextIsDifferentUser ? "10px" : "4px")};
  ${(props) => {
    const { marginLeft, marginRight, textAlign } = getAlignmentStyle(
      props.direction
    );
    return `
        margin-left: ${marginLeft};
        margin-right: ${marginRight};
        text-align: ${textAlign}; 
      `;
  }}
  > img {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

export const MessageWrapper = styled.div<{
  direction: Direction;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.direction === Direction.BOT ? "flex-start" : "flex-end"};
  width: fit-content;
  width: 100%;
`;

export const MessageWrapperContent = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  flex-direction: column;
  border-radius: 0.5rem;
  width: fit-content;
  align-items: flex-end;
  max-width: calc(100% - 30px);
  padding: 0.25rem;
`;

export const MessageContent = styled.div<{
  $fontSize: string;
  $fontColor: string;
}>`
  font-size: ${(props) => props.$fontSize};
  color: ${(props) => props.$fontColor};
  font-family: "DM Sans", sans-serif !important;
`;

export const FileWrapper = styled.a`
  display: flex;
  width: auto;
  height: fit-content;
  cursor: pointer;
`;

export const ImageWrapper = styled.img<{
  hasText: boolean;
}>`
  display: flex;
  width: 290px;
  height: auto;
  object-fit: contain;
  cursor: pointer;
  padding: 6px;
  padding-bottom: 0;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const VideoWrapper = styled.video<{
  hasText: boolean;
}>`
  display: flex;
  width: 290px;
  height: auto;
  object-fit: contain;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  padding: 6px;
  padding-bottom: 0;
`;

export const TextWrapper = styled.div`
  padding: 0 8px;
  padding-top: 6px;
  flex: 1;
  overflow: hidden;
  width: -webkit-fill-available;
  white-space: pre-line;
`;

export const VideoContent = styled.video`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const PlayIcon = styled.span`
  width: 0;
  height: 0;
  margin-left: 2px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 20px solid #e9edef;
  border-radius: 4px;
`;

export const MessageTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 8px;
`;

export const CommentTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CommentTime = styled.div`
  margin: 10px 0;
  text-align: center;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #989898;
  background-color: #f2f2f2;
  padding: 6px;
  border-radius: 10px;
`;
