import styled from "styled-components";

export const Container = styled.span<{
  $fontColor?: string;
  $lineCount?: number;
}>`
  font-family: "DM Sans", sans-serif !important;
  margin-bottom: unset !important;
  user-select: none !important;
  color: ${(props) => props.$fontColor ?? "#62656A"} !important;
  &.text-xxs {
    font-size: 0.625rem !important;
    line-height: 1rem !important;
  }
  &.text-xxs {
    font-size: 12px !important;
  }
  &.text-xs {
    font-size: 12px !important;
  }
  &.text-sm {
    font-size: 14px !important;
  }
  &.text-base {
    font-size: 16px !important;
  }
  &.text-lg {
    font-size: 18px !important;
  }
  &.text-xl {
    font-size: 18px !important;
  }
  &.text-2xl {
    font-size: 20px !important;
  }
  &.text-3xl {
    font-size: 24px !important;
  }
  &.text-4xl {
    font-size: 30px !important;
  }
  &.text-5xl {
    font-size: 36px !important;
  }
  &.text-6xl {
    font-size: 48px !important;
  }
  &.text-7xl {
    font-size: 60px !important;
  }
  &.text-8xl {
    font-size: 72px !important;
  }
  &.text-9xl {
    font-size: 96px !important;
  }
  &.font-thin {
    font-weight: 100 !important;
  }
  &.font-extralight {
    font-weight: 200 !important;
  }
  &.font-light {
    font-weight: 300 !important;
  }
  &.font-normal {
    font-weight: 400;
  }
  &.font-medium {
    font-weight: 500 !important;
  }
  &.font-semibold {
    font-weight: 600 !important;
  }
  &.font-bold {
    font-weight: 700 !important;
  }
  &.font-extrabold {
    font-weight: 800 !important;
  }
  &.font-black {
    font-weight: 900 !important;
  }
`;
